// src/services/blockchainService.js
import Web3 from 'web3';
import AngelusToken from '../contracts/AngelusToken.json';
import AngelusNFT from '../contracts/AngelusNFT.json';
import AngelusNFTArme from '../contracts/AngelusArmeNFT.json';
import AngelusNFTArmure from '../contracts/AngelusArmureNFT.json';
import AngelusMarketplace from '../contracts/AngelusMarketplace.json';
import axios from 'axios';
import { url_backend } from '../config';

export let web3;
export let angelusToken;
export let angelusNFT;
export let angelusNFTArme;
export let angelusNFTArmure;
export let angelusMarketplace;

//export const angelusTokenAddress = 'VOTRE_ADRESSE_CONTRAT_ANGELUS_TOKEN'; // Remplacez par l'adresse réelle

 

if (window.ethereum) {
  web3 = new Web3(window.ethereum);
} else {
  console.error('MetaMask non détecté. Veuillez installer MetaMask.');
  web3 = null;
}
 
export const connectWallet = async () => {
  if (web3) {
    try {
      const accounts = await window.ethereum.request({ method: 'eth_requestAccounts' });
      const account = accounts[0];
      return account;  // Renvoie l'adresse du compte connecté
    } catch (error) {
      console.error('L’utilisateur a refusé l’accès à MetaMask', error);
      return null;
    }
  } else {
    window.alert('Veuillez installer MetaMask pour utiliser cette application.');
    return null;
  }
};

export const loadContracts = async () => {
  const networkId = await web3.eth.net.getId();

  // Charger AngelusToken
  const angelusTokenData = AngelusToken.networks[networkId];
  if (angelusTokenData) {
    angelusToken = new web3.eth.Contract(AngelusToken.abi, angelusTokenData.address);
    //console.log('AngelusToken chargé:', angelusToken);
  } else {
    window.alert('Contrat AngelusToken non déployé sur le réseau détecté.');
  }

  // Charger AngelusNFT
  const angelusNFTData = AngelusNFT.networks[networkId];
  if (angelusNFTData) {
    angelusNFT = new web3.eth.Contract(AngelusNFT.abi, angelusNFTData.address);
    //console.log('AngelusNFT chargé:', angelusNFT);
  } else {
    window.alert('Contrat AngelusNFT non déployé sur le réseau détecté.');
  }

  // Charger AngelusNFTArme
  const angelusNFTArmeData = AngelusNFTArme.networks[networkId];
  if (angelusNFTArmeData) {
    angelusNFTArme = new web3.eth.Contract(AngelusNFTArme.abi, angelusNFTArmeData.address);
    //console.log('AngelusNFTArme chargé:', angelusNFTArme);
  } else {
    window.alert('Contrat AngelusNFTArme non déployé sur le réseau détecté.');
  }

  // Charger AngelusNFTArmure
  const angelusNFTArmureData = AngelusNFTArmure.networks[networkId];
  if (angelusNFTArmureData) {
    angelusNFTArmure = new web3.eth.Contract(AngelusNFTArmure.abi, angelusNFTArmureData.address);
    //console.log('AngelusNFTArmure chargé:', angelusNFTArmure);
  } else {
    window.alert('Contrat Armure non déployé sur le réseau détecté.');
  }

  // Charger AngelusNFTArmure
  const angelusMarketplaceData = AngelusMarketplace.networks[networkId];
  if (angelusMarketplaceData) {
    angelusMarketplace = new web3.eth.Contract(AngelusMarketplace.abi, angelusMarketplaceData.address);
    //console.log('AngelusNFTArmure chargé:', angelusNFTArmure);
  } else {
    window.alert('Contrat Armure non déployé sur le réseau détecté.');
  }


  
};

// getBalance prend maintenant le paramètre account explicitement
export const getBalance = async (account) => {
  if (!angelusToken || !account) return '0';
  try {
   // console.log("Récupération du solde pour le compte :", account);
    const tokenBalance = await angelusToken.methods.balanceOf(account).call();
    
    return web3.utils.fromWei(tokenBalance, 'ether');
  } catch (error) {
    console.error('Erreur lors de la récupération du solde:', error);
    return '0';
  }
};
 

// getNFTs 
export const getNFTs = async (account) => {
  if(!account){
    account = await connectWallet()
  }
  if (!angelusNFT || !account) return [];
  try {
    const nftBalance = await angelusNFT.methods.balanceOf(account).call();
    
    const nfts = [];
    for (let i = 0; i < nftBalance; i++) {
      const tokenId = await angelusNFT.methods.tokenOfOwnerByIndex(account, i).call();
      const characterData = await angelusNFT.methods.getCharacter(tokenId).call();
      nfts.push({ tokenId, ...characterData });
    }

  
    //console.log(nfts)
    // Avant de retourner les NFTs, récupérer les données du backend
    const nftOnBackend = await fetchNftsFromBackend();
    
    if(!nftOnBackend.message){

      /* RECUPERER LA LISTE DES EQUIPEMENT ASSIGN2 */
      const listEquipementAssigne = await fetchEquipment(account);
      //console.log(listEquipementAssigne)

      // Parcourir le tableau nfts et comparer les tokenId avec nftOnBackend
      // Initialiser un tableau pour les NFTs mis à jour
      const updatedNfts = [];
      
      
      nfts.forEach((nft) => {
        
        
        let backendNft = null; // Initialiser la variable
        // Parcourir les NFT dans nftOnBackend pour trouver un match
        nftOnBackend.forEach((bnft) => {
          if (BigInt(bnft.token_id) === nft.tokenId) {
            backendNft = bnft; // Si les tokenId correspondent, on assigne bnft à backendNft
          } 
        });

        // Si un NFT correspondant est trouvé dans le backend, ajouter character_id au NFT
        if (backendNft) {
        //  console.log(backendNft)
        //  console.log('next_combat_time', backendNft.next_combat_time)
         // console.log("backendNft", backendNft)
          let nftToUpdate = {
            ...nft,
            character_id: backendNft.character_id,
          //  attributes: backendNft.
            attributes: {
              ...nft.attributes,  // Conserver les anciens attributs si présents
              ecoScore: backendNft.eco_score,
              endurance: backendNft.endurance,
              agility: backendNft.agility,
              health: backendNft.health,
              intelligence: backendNft.intelligence,
              force: backendNft.strength,
              next_combat_time: backendNft.next_combat_time
            },
          };
        //  console.log("nftToUpdate", nftToUpdate)
          // Parcourir la liste des équipements assignés pour trouver l'équipement correspondant au character_id du NFT
          listEquipementAssigne.selectedWeapons.forEach((equipment) => {
            if (equipment.character_id === nftToUpdate.character_id) {
              nftToUpdate.weapon_id = equipment.weapon_id; // Ajouter le weapon_id si trouvé
            }
          });

          listEquipementAssigne.selectedArmors.forEach((equipment) => {
            if (equipment.character_id === nftToUpdate.character_id) {
              nftToUpdate.armor_id = equipment.armor_id; // Ajouter le armor_id si trouvé
            }
          });

          updatedNfts.push(nftToUpdate);
        } else {
          // Si aucun NFT correspondant n'est trouvé dans le backend, ajouter le NFT tel quel
          updatedNfts.push(nft);
        }
      });
      


      // Trier les NFTs par ordre croissant de tokenId
      updatedNfts.sort((a, b) => {
        // Comparaison des tokenId en BigInt
        return a.tokenId > b.tokenId ? 1 : -1;
      });


      /* reorganisé le tableau updateNFTS par ordre croissant de tokenid */
      return { nfts: updatedNfts, nftBalance: parseInt(nftBalance, 10) , listEquipementAssigne: listEquipementAssigne};
    }
    else{
      console.log("Il n'y a pas de NFT pour ce joueur dans le backend")
      return { nfts: [], nftBalance: parseInt(nftBalance, 10) };
    }
  } catch (error) {
    console.error('Erreur lors de la récupération des NFTs:', error);
    return { nfts: [], nftBalance: 0 };
  }
};
 
export const getNFTsArmure = async (account) => {
  if (!account) {
    account = await connectWallet();
  }
  
  if (!angelusNFTArmure || !account) return [];

  try {
    const nftArmureBalance = await angelusNFTArmure.methods.balanceOf(account).call();
    
    const nftsArmure = [];
    for (let i = 0; i < nftArmureBalance; i++) {
      const tokenId = await angelusNFTArmure.methods.tokenOfOwnerByIndex(account, i).call();
      const armureData = await angelusNFTArmure.methods.getArmure(tokenId).call();
      nftsArmure.push({ tokenId, ...armureData });
    }
   
    // Avant de retourner les NFTs, récupérer les données du backend

    const nftArmureOnBackend = await fetchNftsArmuresFromBackend();
    

    if(!nftArmureOnBackend.message){
      const updatedNftsArmure = [];    
      nftsArmure.forEach((nft) => {
       
        let backendNftArmure = null; // Initialiser la variable
        // Parcourir les NFT dans nftOnBackend pour trouver un match
        nftArmureOnBackend.forEach((bnft) => {
      
          if (BigInt(bnft.token_id) === nft.tokenId) {
            backendNftArmure = bnft; // Si les tokenId correspondent, on assigne bnft à backendNft
           
          } 
        });
 
        // Si un NFT correspondant est trouvé dans le backend, ajouter weapon_id au NFT
        if (backendNftArmure) {
         
          updatedNftsArmure.push({
            ...nft,
            armor_id: backendNftArmure.armor_id, // Ajouter le character_id
          });
        } else {
          // Si aucun NFT correspondant n'est trouvé dans le backend, ajouter le NFT tel quel
          updatedNftsArmure.push(nft);
        }
      
        // Trier les NFTs par ordre croissant de tokenId
        updatedNftsArmure.sort((a, b) => {
          // Comparaison des tokenId en BigInt
          return a.tokenId > b.tokenId ? 1 : -1;
        });
       
        
      });
        /* reorganisé le tableau updateNFTS par ordre croissant de tokenid */
        return { nftsArmure: updatedNftsArmure, nftArmureBalance: parseInt(nftArmureBalance, 10) };
      
    }
    else{
      console.log("Il n'y a pas de NFT pour ce joueur dans le backend")
      return { nftsArmure: [], nftArmureBalance: parseInt(nftArmureBalance, 10) };
    }
    

  } catch (error) {
    console.error('Erreur lors de la récupération des NFTs Armure :', error);
    return { nftsArmure: [], nftArmureBalance: 0 };
  }
};

export const getNFTsArme = async (account) => {
  if(!account){
    account = await connectWallet()
  }
  if (!AngelusNFTArme || !account) return [];
    
  try {
    const nftArmesBalance = await angelusNFTArme.methods.balanceOf(account).call();
    
    const nftsArmes = [];
    for (let i = 0; i < nftArmesBalance; i++) {
      const tokenId = await angelusNFTArme.methods.tokenOfOwnerByIndex(account, i).call();
      const armeData = await angelusNFTArme.methods.getArme(tokenId).call();
      nftsArmes.push({ tokenId, ...armeData });
    }
   

    const nftArmeOnBackend = await fetchNftsArmesFromBackend();
   
    if(!nftArmeOnBackend.message){
      const updatedNftsArme = [];    
      nftsArmes.forEach((nft) => {
        let backendNftArme = null; // Initialiser la variable
        // Parcourir les NFT dans nftOnBackend pour trouver un match
        nftArmeOnBackend.forEach((bnft) => {
      
          if (BigInt(bnft.token_id) === nft.tokenId) {
            backendNftArme = bnft; // Si les tokenId correspondent, on assigne bnft à backendNft
           
          } 
        });  

        // Si un NFT correspondant est trouvé dans le backend, ajouter weapon_id au NFT
        if (backendNftArme) {
         
          updatedNftsArme.push({
            ...nft,
            weapon_id: backendNftArme.weapon_id, // Ajouter le character_id
          });
        } else {
          // Si aucun NFT correspondant n'est trouvé dans le backend, ajouter le NFT tel quel
          updatedNftsArme.push(nft);
        }

        // Trier les NFTs par ordre croissant de tokenId
        updatedNftsArme.sort((a, b) => {
          // Comparaison des tokenId en BigInt
          return a.tokenId > b.tokenId ? 1 : -1;
        });

      });
      /* reorganisé le tableau updateNFTS par ordre croissant de tokenid */
      return { nftsArme: updatedNftsArme, nftArmeBalance: parseInt(nftArmesBalance, 10) };
    
    }
    else{
      console.log("Il n'y a pas de NFT pour ce joueur dans le backend")
      return { nftsArmes: [], nftArmesBalance: parseInt(nftArmesBalance, 10) };
    }
    
    
    

  } catch (error) {
    console.error('Erreur lors de la récupération des NFTs Armure :', error);
    return { nftsArme: [], nftArmesBalance: 0 };
  }
};

export const getPlayerIdFromBackend = async (walletAddress) => {
  try {
//console.log(walletAddress)
    // Requête pour vérifier si le joueur existe et obtenir le player_id
    const response = await axios.post(`${url_backend}/players/checkIfPlayerExist`, {
      wallet_address: walletAddress,
    });

    if (response.data && response.data.player_id) {
      return response.data.player_id; // Retourner le player_id trouvé
    }

    console.warn('Aucun player_id trouvé pour ce wallet:', walletAddress);
    return null;
  } catch (error) {
    console.error('Erreur lors de la récupération du player_id depuis le backend:', error);
    return null;
  }
};
export const fetchEquipment = async (connectedAccount) => {
  try {
    const playerIdBD = await getPlayerIdFromBackend(connectedAccount);
    
    //console.log("playerIdBD ", playerIdBD);
    // Appel à la nouvelle route pour récupérer les personnages du joueur et leurs équipements
    const response = await axios.get(`${url_backend}/inventory/${playerIdBD}/characters-with-equipment`);
    if(response.data.success){
      const charactersWithEquipment = response.data.characters;

      // Vous pouvez maintenant traiter les données des personnages et équipements récupérés
      // Par exemple, vous pouvez séparer les armes et armures sélectionnées :
      const selectedWeapons = charactersWithEquipment.map(character => ({
        character_id: character.character_id,
        weapon_id: character.equipment?.weapon_id || null
      }));

      const selectedArmors = charactersWithEquipment.map(character => ({
        character_id: character.character_id,
        armor_id: character.equipment?.armor_id || null
      }));

      // Logique pour gérer les armes et armures sélectionnées, vous pouvez aussi les stocker dans l'état
      //console.log("Selected Weapons: ", selectedWeapons);
      //console.log("Selected Armors: ", selectedArmors);

      // Retourner les informations (facultatif)
      return {
        selectedWeapons,
        selectedArmors
      };
    }
    else{
      const selectedWeapons = [];
      const selectedArmors = [];
     // console.log("Selected Weapons: ", selectedWeapons);
     // console.log("Selected Armors: ", selectedArmors);
      return {
        selectedWeapons,
        selectedArmors
      };
    }
    
  } catch (error) {
    console.error('Erreur lors de la récupération des équipements sélectionnés:', error);
    throw error; // Vous pouvez également propager l'erreur si nécessaire
  }
};

export const fetchNftsFromBackend = async () => {
  try {
    const accountWallet = await connectWallet()
    //console.log("accountWallet = " + accountWallet)
    const playerIdBD = await getPlayerIdFromBackend(accountWallet);
    //console.log("playerIdBD = "+ playerIdBD)
    
    const response = await axios.get(`${url_backend}/characters/${playerIdBD}/nfts`);
    const nfts = response.data;

    return nfts;
  } catch (error) {
    console.error("Erreur lors de la récupération des NFTs depuis le backend :", error);
    return [];
  }
};

export const fetchNftsArmuresFromBackend = async () => {
  try {
    const accountWallet = await connectWallet()
    //console.log("accountWallet = " + accountWallet)
    const playerIdBD = await getPlayerIdFromBackend(accountWallet);
    //console.log("playerIdBD = "+ playerIdBD)
    
    const response = await axios.get(`${url_backend}/inventory/${playerIdBD}/armures/nfts`);
    const nfts = response.data;

    return nfts;
  } catch (error) {
    console.error("Erreur lors de la récupération des NFTs Armures depuis le backend :", error);
    return [];
  }
};

export const fetchNftsArmesFromBackend = async () => {
  try {
    const accountWallet = await connectWallet()
    //console.log("accountWallet = " + accountWallet)
    const playerIdBD = await getPlayerIdFromBackend(accountWallet);
    //console.log("playerIdBD = "+ playerIdBD)
    
    const response = await axios.get(`${url_backend}/inventory/${playerIdBD}/armes/nfts`);
    const nfts = response.data;

    return nfts;
  } catch (error) {
    console.error("Erreur lors de la récupération des NFTs Armes depuis le backend :", error);
    return [];
  }
};