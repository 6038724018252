import { loadBlockchainAndBackendData } from './loadBlockchainAndBackendDataService';
import { connectWallet  } from '../services/blockchainService';

export const toggleAccountMenu = (dispatch) => {
  dispatch({ type: 'TOGGLE_ACCOUNT_MENU' });
};

export const handleLogout = (dispatch) => {
  dispatch({ type: 'SET_ACCOUNT', payload: '' });
  dispatch({ type: 'SET_BALANCE', payload: '0' });
  dispatch({ type: 'SET_NFT_BALANCE', payload: '0' });
  dispatch({ type: 'SET_NFTS', payload: [] });
  dispatch({ type: 'TOGGLE_ACCOUNT_MENU' });
  alert('Vous devez vous déconnecter de MetaMask manuellement.');
};
 
export const handleMintSuccess = async (dispatch, handleConnectWallet) => {
  dispatch({ type: 'SET_CHARACTER_MINTED', payload: false });
  dispatch({ type: 'SET_SHOW_MODAL', payload: false });
  await handleConnectWallet(); // Recharger les données après mint
};

export const nextNfts = (state, dispatch) => {
  if (state.currentIndex + 2 < state.nftBalance) {
    dispatch({ type: 'SET_CURRENT_INDEX', payload: state.currentIndex + 2 });
  }
};

export const prevNfts = (state, dispatch) => {
  if (state.currentIndex > 0) {
    dispatch({ type: 'SET_CURRENT_INDEX', payload: state.currentIndex - 2 });
  }
};
   
export const handleNftClick = (dispatch, nft) => {
  dispatch({ type: 'SET_SELECTED_NFT', payload: nft });
  dispatch({ type: 'SET_SHOW_MODAL_DETAIL', payload: true });
};
  
export const closeDetailModal = async (dispatch, connectedAccount, loadBlockchainAndBackendData) => {
  dispatch({ type: 'SET_IS_LOADING', payload: true });
  // Vérifiez si connectedAccount est défini, sinon reconnectez-vous
  let account = connectedAccount;
  if (!account) {
    account = await connectWallet();
  }
  
  if (account) {
    dispatch({ type: 'SET_ACCOUNT', payload: account });
    await loadBlockchainAndBackendData(account, dispatch);
  }
  
  dispatch({ type: 'SET_SHOW_MODAL_DETAIL', payload: false });
  dispatch({ type: 'SET_IS_LOADING', payload: false });
};

export const closeCreateCharacterModal = (dispatch) => {
  dispatch({ type: 'SET_SHOW_MODAL', payload: false });
  dispatch({ type: 'SET_CHARACTER_MINTED', payload: false });
  dispatch({ type: 'SET_SELECTED_NFT', payload: null });
};

 
export const openBuyModal = (dispatch) => {
    dispatch({ type: 'SET_SHOW_MODAL_BUY_ANGELUS', payload: true });
  };

//export const closeBuyModal = () => dispatch({ type: 'SET_SHOW_MODAL', payload: false });
export const closeBuyModal = async (dispatch) => {
    const accountToUse = await connectWallet();
    if (accountToUse) {
      await loadBlockchainAndBackendData(accountToUse, dispatch); // Notez que loadBlockchainAndBackendData prend dispatch en second
    }
    dispatch({ type: 'SET_SHOW_MODAL_BUY_ANGELUS', payload: false }); // Fermer le modal après la mise à jour des données
  }; 


// Fonction pour ouvrir le modal du marché
export const openMarketplaceModal = (dispatch) => {
  dispatch({ type: 'OPEN_MARKETPLACE_MODAL' });
};

// Fonction pour fermer le modal du marché
export const closeMarketplaceModal = (dispatch) => {
  dispatch({ type: 'CLOSE_MARKETPLACE_MODAL' });
};
