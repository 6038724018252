import React, { useState, useRef  } from 'react';

import {  url_backend, url_base, timeoutGenerateCharacter, gas_mint_createCharacter, gasPrice_mint_createCharacter, maxRegencharacter } from '../config';
import { v4 as uuidv4 } from 'uuid'; // Assurez-vous d'installer la bibliothèque uuid
import axios from 'axios';
import { web3, angelusNFT, getPlayerIdFromBackend, connectWallet  } from '../services/blockchainService';
import { validateCharacterData } from '../services/outils';

// Optionnel : Importer JSONbig si vous souhaitez l'utiliser
// import JSONbig from 'json-bigint';

  

const CreateCharacter = ({ account, onCharacterCreated, generateCount, setGenerateCount, characterMinted, setCharacterMinted, onMintSuccess, playerId , dispatch  }) => {
  const [newCharacter, setNewCharacter] = useState(null);
  const [isFlipped, setIsFlipped] = useState(false); // État pour le retournement
  const canvasRef = useRef(null);
  

  //const [imageFile, setImageFile] = useState(null); // État pour le fichier image

  const closeModal = () => {
    setNewCharacter(null); // Réinitialise le personnage créé
    setCharacterMinted(false); // Réinitialise l'état de mint
    onMintSuccess(); // Appel pour mettre à jour la liste des NFTs
  };


   
   
  
  const generateCharacter = async () => {
    dispatch({ type: 'SET_IS_LOADING', payload: true });

    try {
      const response = await axios.get(`${url_backend}/characters/generate`);
      const characterData = response.data;

      setNewCharacter(characterData);
      onCharacterCreated(characterData);
      // Ajouter un délai avant de retirer le loader
      setTimeout(() => {
        dispatch({ type: 'SET_IS_LOADING', payload: false });
      }, timeoutGenerateCharacter); 
    } catch (error) {
      console.error('Erreur lors de la génération du personnage:', error);
      dispatch({ type: 'SET_IS_LOADING', payload: false });
    }
  };

  const mintCharacterNFT = async () => {
    dispatch({ type: 'SET_IS_LOADING', payload: true });
 
    if (!web3) {
        console.error('web3 non défini. Assurez-vous que MetaMask est installé.');
        dispatch({ type: 'SET_IS_LOADING', payload: false });
        return;
    }

    //const accounts = await web3.eth.getAccounts();
    //const account = accounts[0];

    if (!angelusNFT) {
        console.error("Le contrat AngelusNFT n'a pas pu être chargé !");
        dispatch({ type: 'SET_IS_LOADING', payload: false });
        return;
    }

    if (!newCharacter) {
        console.error("Aucun personnage à mint !");
        dispatch({ type: 'SET_IS_LOADING', payload: false });
        return;
    }

    // Utiliser le compte passé via les props plutôt que de refaire un appel à web3.eth.getAccounts()
    var accountToUse = account;

    // **Télécharger l'image combinée et l'image de fond**
    try {
        const combinedImageBlob = await fetch(newCharacter.image).then(res => res.blob());
        const combinedImageFormData = new FormData();
        const combinedImageFileName = `frontImage_${uuidv4()}.png`;
        combinedImageFormData.append("image", combinedImageBlob, combinedImageFileName);
        combinedImageFormData.append("wallet", account);

        //const uploadCombinedImageResponse = await axios.post(`${url_backend}/upload`, combinedImageFormData);

        const uploadCombinedImageResponse = await axios.post(`${url_backend}/characters/upload`, combinedImageFormData, {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        });
        /*const uploadCombinedImageResponse = await axios.post(`${url_backend}/upload`, combinedImageFormData, {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        });*/

        if (uploadCombinedImageResponse.status !== 200) {
          console.error("Erreur lors de l'upload de l'image combinée :", uploadCombinedImageResponse.data);
          return;
      }

      const combinedImagePath = url_base + uploadCombinedImageResponse.data.filePath;

       // const combinedImagePath = uploadCombinedImageResult.filePath;

        const backgroundImageBlob = await fetch(newCharacter.background).then(res => res.blob());
        const backgroundImageFormData = new FormData();
        const backgroundImageFileName = `backgroundImage_${uuidv4()}.png`;
        backgroundImageFormData.append("image", backgroundImageBlob, backgroundImageFileName);
        backgroundImageFormData.append("wallet", account);


        const uploadBackgroundImageResponse = await axios.post(`${url_backend}/characters/upload`, backgroundImageFormData, {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        });

        if (uploadBackgroundImageResponse.status !== 200) {
            console.error("Erreur lors de l'upload de l'image de fond :", uploadBackgroundImageResponse.data);
            return;
        }

        const backgroundImagePath = url_base + uploadBackgroundImageResponse.data.filePath;

       
        
        // **Préparer les données de personnage**
        const characterData = {
            name: newCharacter.name,
            race: newCharacter.raceName,
            class: newCharacter.className,
            gender: newCharacter.gender,
            imageFront: combinedImagePath,
            imageBack: backgroundImagePath,
            attributes: {
                size: Number(newCharacter.size),
                weight: Number(newCharacter.weight),
                endurance: Number(newCharacter.endurance),
                force: Number(newCharacter.force),
                agility: Number(newCharacter.agility),
                intelligence: Number(newCharacter.intelligence),
                ecoScore: Number(newCharacter.ecoScore),
            },
            raceAbilities: {
                raceAbilitiesFirst: {
                    name: newCharacter.raceAbilities[0].name,
                    points: Number(newCharacter.raceAbilities[0].points),
                },
                raceAbilitiesSecond: {
                    name: newCharacter.raceAbilities[1].name,
                    points: Number(newCharacter.raceAbilities[1].points),
                },
                raceAbilitiesThird: {
                    name: newCharacter.raceAbilities[2].name,
                    points: Number(newCharacter.raceAbilities[2].points),
                },
            },
            classActions: {
                classActionsAttack: {
                    classActionsAttackFirst: {
                        name: newCharacter.classActions.attack[0].name,
                        points: Number(newCharacter.classActions.attack[0].points),
                    },
                    classActionsAttackSecond: {
                        name: newCharacter.classActions.attack[1].name,
                        points: Number(newCharacter.classActions.attack[1].points),
                    },
                    classActionsAttackThird: {
                        name: newCharacter.classActions.attack[2].name,
                        points: Number(newCharacter.classActions.attack[2].points),
                    },
                },
                classActionsDefense: {
                    classActionsDefenseFirst: {
                        name: newCharacter.classActions.defense[0].name,
                        points: Number(newCharacter.classActions.defense[0].points),
                    },
                    classActionsDefenseSecond: {
                        name: newCharacter.classActions.defense[1].name,
                        points: Number(newCharacter.classActions.defense[1].points),
                    },
                    classActionsDefenseThird: {
                        name: newCharacter.classActions.defense[2].name,
                        points: Number(newCharacter.classActions.defense[2].points),
                    },
                },
                classActionsHeal: {
                    classActionsHealFirst: {
                        name: newCharacter.classActions.heal[0].name,
                        points: Number(newCharacter.classActions.heal[0].points),
                    },
                    classActionsHealSecond: {
                        name: newCharacter.classActions.heal[1].name,
                        points: Number(newCharacter.classActions.heal[1].points),
                    },
                    classActionsHealThird: {
                        name: newCharacter.classActions.heal[2].name,
                        points: Number(newCharacter.classActions.heal[2].points),
                    },
                },
            },
        };
 
       // console.log(characterData)
        // **Mint gratuit (uniquement les frais de gas en MATIC)**
        console.log("Mint gratuit pour tous les utilisateurs.");
      
        // **Effectuer le mint réel**
        if (typeof angelusNFT.methods.mint !== 'function') {
            console.error("La méthode mint n'est pas disponible sur le contrat AngelusNFT !");
            dispatch({ type: 'SET_IS_LOADING', payload: false });
            return;
        }

        //console.log(characterData)
        //console.log("validation validateCharacterData")
        // **Vérifier que characterData est valide**
        validateCharacterData(characterData);
        //console.log(characterData)

        try {
            const receipt = await angelusNFT.methods.mint(characterData).send({
                from: accountToUse,
                gas: gas_mint_createCharacter,
                gasPrice: web3.utils.toWei(gasPrice_mint_createCharacter, 'gwei'),
            });

           // console.log(receipt)
            const mintedEvent = receipt.events.Minted;
            const tokenId = mintedEvent.returnValues.tokenId.toString();
            if(!playerId){
              if(!accountToUse){
                accountToUse = await connectWallet();
              }
              playerId = await getPlayerIdFromBackend(accountToUse);

            }  
 
           // console.log(tokenId)
            await saveCharacterToDB({
                token_id: tokenId,
                name: characterData.name,
                race: characterData.race,
                class: characterData.class,
                gender: characterData.gender,
                size: characterData.attributes.size,
                weight: characterData.attributes.weight,
                endurance: characterData.attributes.endurance,
                strength: characterData.attributes.force,
                agility: characterData.attributes.agility,
                intelligence: characterData.attributes.intelligence,
                eco_score: characterData.attributes.ecoScore,
                image_front: characterData.imageFront,
                image_back: characterData.imageBack,
                player_id: playerId,
            });

            console.log('Personnage minté avec succès !');
            closeModal();
            setCharacterMinted(true);
            onMintSuccess();
        } catch (error) {
            console.error("Erreur lors du minting:", error);
        } finally {
            dispatch({ type: 'SET_IS_LOADING', payload: false });
            setCharacterMinted(true);
        }
    } catch (error) {
        console.error("Erreur lors de la génération ou de l'upload des images :", error);
    }
};

  
  
const saveCharacterToDB = async (characterData) => {
  // Convertir tous les BigInt en chaînes de caractères (si non fait auparavant)
  const sanitizedData = JSON.parse(JSON.stringify(characterData, (key, value) => {
    if (typeof value === 'bigint') {
      return value.toString();
    }
    return value;
  }));

  try {
    const response = await axios.post(`${url_backend}/characters/savePersoToDB`, sanitizedData);
    console.log('Personnage enregistré dans la base de données:', response.data);
  } catch (error) {
    console.error('Erreur lors de l\'enregistrement du personnage:', error);
  }
};



  const handleCardFlip = () => {
    setIsFlipped(!isFlipped);
  };


  
  


  const regenerateCharacter = () => {
    if (generateCount < maxRegencharacter) {
      setGenerateCount(generateCount - 1);
      generateCharacter();
    } else if (characterMinted) {
      window.alert('Personnage déjà minté. Vous ne pouvez plus régénérer.');
    } else {
      window.alert('Limite de régénérations atteinte.');
    }
  };

 
return (
  <div>
    
    <canvas ref={canvasRef} style={{ display: 'none', width: 200, height: 200 }} />
    {!newCharacter ? (
      <div>
        
        <button className='bp_generate_perso' onClick={generateCharacter}>Générer un personnage</button>
      </div>
    ) : (
      <div className={`modal-container-create`}>
        <div className="card-container"> {/* Conteneur centré */}
          <div className={`card ${isFlipped ? 'flipped' : ''}`} onClick={handleCardFlip}>
            <div className="card-front">
              <img src={newCharacter.image} alt="Personnage généré" />
              <div className="character-info">
                <div className='character-header'>
                  <h2 className="character-name">{newCharacter.name}</h2>
                  <h4 className="character-gender">{newCharacter.gender}</h4>
                </div>
                <div className='character-body'>
                  <div className="character-stats-left">
                    <p> {newCharacter.size} cm</p>
                    <p>{newCharacter.weight} kg</p>
                    
                  </div>
                  <div className="character-stats-right">
                    <p><strong>Endurance :</strong> {newCharacter.endurance}</p>
                    <p><strong>Force :</strong> {newCharacter.force}</p>
                    <p><strong>Agilité :</strong> {newCharacter.agility}</p>
                    <p><strong>Intelligence :</strong> {newCharacter.intelligence}</p>
                    <p><strong>EcoScore :</strong> {newCharacter.ecoScore}</p>
                  </div>
                </div>
                <div className='character-footer'>
                  <h4 className="character-race">{newCharacter.raceName}</h4>
                  <h4 className="character-class">{newCharacter.className}</h4>
                </div>
              </div>
            </div> 
            <div className="card-back">
              <img src={newCharacter.background} alt="Fond"  />
              <div className="abilities-info">
                <div className='abilities-info-raciale'>
                  <h4>Aptitudes raciales :</h4>
                  <ul>
                    {newCharacter.raceAbilities.map((ability, index) => (
                      <li key={index}>{ability.name} (Points: {ability.points})</li>
                    ))}
                  </ul>
                </div>
                <div className='abilities-info-class'>
                  <h4>Actions de classe :</h4>
                  <div className='abilities-info-class-attack'>
                    <h5>Attaque :</h5>
                    <ul>
                      {newCharacter.classActions.attack.map((attack, index) => (
                        <li key={index}>{attack.name} (Points: {attack.points})</li>
                      ))}
                    </ul>
                  </div>
                  <div className='abilities-info-class-defense'>
                    <h5>Défense :</h5>
                    <ul>
                      {newCharacter.classActions.defense.map((defense, index) => (
                        <li key={index}>{defense.name} (Points: {defense.points})</li>
                      ))}
                    </ul>
                  </div>
                  <div className='abilities-info-class-heal'>
                    <h5>Soin :</h5>
                    <ul>
                      {newCharacter.classActions.heal.map((heal, index) => (
                        <li key={index}>{heal.name} (Points: {heal.points})</li>
                      ))}
                    </ul>
                  </div>
                </div>
              </div>

            </div>
          </div>
        </div> 
      {/* Ajout des boutons de Mint et de régénération */}
    {newCharacter && (
        <div className='bp_create_character'>
          <div className='bp_create_mint'>
            <button onClick={mintCharacterNFT}>Mint mon personnage NFT</button>
            <button onClick={regenerateCharacter} style={{ marginLeft: '20px' }}>
              Régénérer le personnage ({generateCount} tentatives restantes)
            </button>
          </div>
          <div className='bp_create_mint'>
            <button onClick={closeModal} >Annuler</button>
          </div>
        </div>
    )}
      </div>
    )}

 

    
  </div>
  );
};

export default React.memo(CreateCharacter);
