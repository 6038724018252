import React, { useEffect, useCallback  } from 'react';
import axios from 'axios';
import { url_backend, url_base, maxRegenItem, gas_mint_arme, gasPrice_mint_arme, gas_mint_armure, gasPrice_mint_armure } from '../config';
import { web3,  angelusNFTArme, angelusNFTArmure, getPlayerIdFromBackend, connectWallet  } from '../services/blockchainService';
import { v4 as uuidv4 } from 'uuid'; 

const ModalGenerateItem = ({ selectedType, dispatch, state, connectedAccount,  handleMintSuccessItem, onClose }) => {
 

  
 
  // Fonction pour générer une armure
  const generateArmure = useCallback(async () => {
    dispatch({ type: 'SET_IS_LOADING', payload: true });
    try {
      const response = await axios.get(`${url_backend}/inventory/generateArmor`);
   //   console.log(response.data);
      dispatch({ type: 'SET_SELECTED_NFT_ARMORS', payload: response.data });
    } catch (error) {
      console.error('Erreur lors de la génération de l\'armure:', error);
    }
    dispatch({ type: 'SET_IS_LOADING', payload: false });
  }, [dispatch]);
  
  const generateArme = useCallback(async () => {
    dispatch({ type: 'SET_IS_LOADING', payload: true });
    try {
      const response = await axios.get(`${url_backend}/inventory/generateWeapon`);
    //  console.log(response.data);
      dispatch({ type: 'SET_SELECTED_NFT_WEAPONS', payload: response.data });
    } catch (error) {
      console.error('Erreur lors de la génération de l\'arme:', error);
    }
    dispatch({ type: 'SET_IS_LOADING', payload: false });
  }, [dispatch]);
  
 
  // Fonction pour générer ou régénérer l'item
  const regenerateItem = () => {
     
   // console.log(state.generateCount)
    if (state.generateCount < maxRegenItem && state.generateCount > 0) {
      dispatch({ type: 'SET_GENERATE_COUNT', payload: state.generateCount - 1 })      
      if(selectedType === "armure"){
        generateArmure();
      }
      if(selectedType === "arme"){
        generateArme();
      }
    } 
    else {
      window.alert('Limite de régénérations atteinte.');
    }

  };

  // Sélectionner le bon NFT en fonction du type d'item (armure ou arme)
  const selectedNFTItem = selectedType === 'armure' ? state.selectedNftArmors : state.selectedNftWeapons;

  // Fonctions de mint (à définir)
  const mintArmure = async (item) => {
    // Logique pour mint une armure
    alert(`Minting armure: ${item.name}`);
    dispatch({ type: 'SET_IS_LOADING', payload: true });

    if (!web3) {
      console.error('web3 non défini. Assurez-vous que MetaMask est installé.');
      dispatch({ type: 'SET_IS_LOADING', payload: false });
      return;
    }

    if (!angelusNFTArmure) {
      console.error("Le contrat angelusNFTArmure n'a pas pu être chargé !");
      dispatch({ type: 'SET_IS_LOADING', payload: false });
      return;
    }

    if (!item) {
        console.error("Aucune Armure à mint !");
        dispatch({ type: 'SET_IS_LOADING', payload: false });
        return;
    }

    if (!connectedAccount) {
      connectedAccount = await connectWallet();
    }
    
    if (connectedAccount) {
      dispatch({ type: 'SET_ACCOUNT', payload: connectedAccount });
    }
    else{
      console.error("le wallet n'a pas été chagé !");
    }

    try {
     //connectedAccount);
      const imageBlob = await fetch(item.image).then(res => res.blob());
      const imageFormData = new FormData();
      const imageFileName = `${uuidv4()}.png`;

      imageFormData.append("image", imageBlob, imageFileName);
      imageFormData.append("wallet", connectedAccount);

      const uploadImageResponse = await axios.post(`${url_backend}/inventory/uploadArmure`, imageFormData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      });

      if (uploadImageResponse.status !== 200) {
        console.error("Erreur lors de l'upload de l'image combinée :", uploadImageResponse.data);
        return;
      }

      const imagePath = url_base + uploadImageResponse.data.filePath;  
    //  console.log(imagePath)
      const armureData = {
        name: item.name,
        force: Number(item.force),
        endurance: Number(item.endurance),
        agility: Number(item.agility),
        intelligence: Number(item.intelligence),
        ecoscore: Number(item.eco_score),
        armureType: item.type,
        imageFront: imagePath,
        rarity: item.rarity,
        defense: Number(item.defense)
       }
 
      // **Mint gratuit (uniquement les frais de gas en MATIC)**
      console.log("Mint gratuit pour tous les utilisateurs.");
 
      // **Effectuer le mint réel**
      if (typeof angelusNFTArme.methods.mint !== 'function') {
        console.error("La méthode mint n'est pas disponible sur le contrat angelusNFTArme !");
        dispatch({ type: 'SET_IS_LOADING', payload: false });
        return;
      }

      
      try {
       // console.log(armureData)

        /* MINT */
        const receipt = await angelusNFTArmure.methods.mint(
            armureData.name,
            armureData.force,
            armureData.endurance,
            armureData.agility,
            armureData.intelligence,
            armureData.ecoscore,
            armureData.armureType,
            armureData.imageFront,
            armureData.rarity,
            armureData.defense
        ).send({
            from: connectedAccount,
            gas: gas_mint_armure,
            gasPrice: web3.utils.toWei(gasPrice_mint_armure, 'gwei'),
        });

   

        const mintedEvent = receipt.events.Minted;
        const tokenId = mintedEvent.returnValues.tokenId.toString();

        if(!connectedAccount){
          connectedAccount = await connectWallet();
        }
        const playerId = await getPlayerIdFromBackend(connectedAccount);


        /* SAVE TO DB ET CLOSE MODAL  */

      //  console.log(tokenId)
       // console.log(playerId)
        armureData.token_id = tokenId;
        await saveArmureToDB(armureData, playerId);

        onClose();
        handleMintSuccessItem();


        
      } catch (error) {
        console.error("Erreur lors du minting:", error);
      } finally {
          dispatch({ type: 'SET_IS_LOADING', payload: false });
          //setCharacterMinted(true);
      }

    } catch (error) {
      console.error("Erreur lors de la génération ou de l'upload des images :", error);
    } 

  };

  const mintArme = async (item) => {
    // Logique pour mint une arme
    alert(`Minting arme: ${item.name}`);
    dispatch({ type: 'SET_IS_LOADING', payload: true });

    if (!web3) {
      console.error('web3 non défini. Assurez-vous que MetaMask est installé.');
      dispatch({ type: 'SET_IS_LOADING', payload: false });
      return;
    }

    if (!angelusNFTArme) {
      console.error("Le contrat angelusNFTArme n'a pas pu être chargé !");
      dispatch({ type: 'SET_IS_LOADING', payload: false });
      return;
    }

    if (!item) {
        console.error("Aucune Arme à mint !");
        dispatch({ type: 'SET_IS_LOADING', payload: false });
        return;
    }

    connectedAccount = await connectWallet();
    if (connectedAccount) {
      dispatch({ type: 'SET_ACCOUNT', payload: connectedAccount });
    }
    else{
      console.error("le wallet n'a pas été chagé !");
    }

    
    // Fonction pour sauvegarder une arme dans la base de données


    try {
    //  console.log(connectedAccount)
      const imageBlob = await fetch(item.image).then(res => res.blob());
      const imageFormData = new FormData();
      const imageFileName = `${uuidv4()}.png`;

      imageFormData.append("image", imageBlob, imageFileName);
      imageFormData.append("wallet", connectedAccount);
      
      const uploadImageResponse = await axios.post(`${url_backend}/inventory/uploadArme`, imageFormData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      });

      if (uploadImageResponse.status !== 200) {
        console.error("Erreur lors de l'upload de l'image combinée :", uploadImageResponse.data);
        return;
      }

      const imagePath = uploadImageResponse.data.filePath;  
    //  console.log(imagePath)
      const armeData = { 
        name: item.name,
        force: Number(item.force),
        endurance: Number(item.endurance),
        agility: Number(item.agility),
        intelligence: Number(item.intelligence),
        ecoscore: Number(item.eco_score),
        armeType: item.type,
        imageFront: imagePath,
        rarity: item.rarity,
        attack: Number(item.attack)
      }

      // **Mint gratuit (uniquement les frais de gas en MATIC)**
      console.log("Mint gratuit pour tous les utilisateurs.");

      // **Effectuer le mint réel**
      if (typeof angelusNFTArme.methods.mint !== 'function') {
        console.error("La méthode mint n'est pas disponible sur le contrat angelusNFTArme !");
        dispatch({ type: 'SET_IS_LOADING', payload: false });
        return;
      }


      try {
      //  console.log(armeData)

        /* MINT */
        if(!connectedAccount){
          connectedAccount = await connectWallet();
          dispatch({ type: 'SET_ACCOUNT', payload: connectedAccount });
        }


        const receipt = await angelusNFTArme.methods.mint(
          armeData.name,
          armeData.force,
          armeData.endurance,
          armeData.agility,
          armeData.intelligence,
          armeData.ecoscore,
          armeData.armeType,
          armeData.imageFront,
          armeData.rarity,
          armeData.attack
      ).send({
          from: connectedAccount,
          gas: gas_mint_arme,
          gasPrice: web3.utils.toWei(gasPrice_mint_arme, 'gwei'),
      });

        const mintedEvent = receipt.events.Minted;
        const tokenId = mintedEvent.returnValues.tokenId.toString();



        if(!connectedAccount){
          connectedAccount = await connectWallet();
        }
        const playerId = await getPlayerIdFromBackend(connectedAccount);

        /* SAVE TO DB ET CLOSE MODAL  */
      //  console.log(tokenId)
      //  console.log(playerId)
        armeData.token_id = tokenId;
        await saveArmeToDB(armeData, playerId);
        onClose();
        handleMintSuccessItem();
      
      

      } catch (error) {
        console.error("Erreur lors du minting:", error);
      } finally {
          dispatch({ type: 'SET_IS_LOADING', payload: false });
         // setCharacterMinted(true);
      }




    } catch (error) {
      console.error("Erreur lors de la génération ou de l'upload des images :", error);
    }  

  };

  const saveArmeToDB = async (armeData, playerId) => {
    const sanitizedData = {
      token_id: armeData.token_id,
      name: armeData.name,
      type: armeData.armeType,
      attack_value: armeData.force, // Ajuster selon votre logique
      rarity: armeData.rarity,
      image: armeData.imageFront,
      player_id: playerId, // Ajout du player_id
    };

   
    try {
      const response = await axios.post(`${url_backend}/inventory/saveArmeToDB`, sanitizedData);
      console.log('Arme enregistrée dans la base de données:', response.data);
    } catch (error) {
      console.error('Erreur lors de l\'enregistrement de l\'arme:', error);
    }
  };


    // Fonction pour sauvegarder une armure dans la base de données
    const saveArmureToDB = async (armureData, playerId) => {
      const sanitizedData = {
        token_id: armureData.token_id,
        name: armureData.name,
        type: armureData.armureType,
        defense_value: armureData.endurance, // Ajuster selon votre logique
        rarity: armureData.rarity,
        image: armureData.imageFront,
        player_id: playerId, // Ajout du player_id
      };
   //   console.log(sanitizedData)
      try {
        const response = await axios.post(`${url_backend}/inventory/saveArmureToDB`, sanitizedData);
        console.log('Armure enregistrée dans la base de données:', response.data);
      } catch (error) {
        console.error('Erreur lors de l\'enregistrement de l\'armure:', error);
      }
    };
    useEffect(() => {
      if (!selectedNFTItem) {
        selectedType === 'armure' ? generateArmure() : generateArme();
      }
    }, [selectedNFTItem, selectedType, generateArmure, generateArme]);
    
 
  return (
    <div className={`modal-container-item`}>
      {selectedNFTItem ? (
        <div className="item-card-container">
          <div className="item-card">
            <div className="item-card-front">
              <img src={selectedNFTItem.image} alt={`${selectedNFTItem.name}`} />
              <div className="item-info">
                <div className='item-header'>
                  <h2 className="item-name">{selectedNFTItem.name}</h2>
                  <h4 className="item-name">{selectedNFTItem.type}</h4>
                  <h3 className={`item-name-rarity ${selectedNFTItem.rarity.toLowerCase()}`}>
                    {selectedNFTItem.rarity.toString()}
                  </h3>
                </div>
                <div className='item-body'>
                  <div className="item-stats-left">
                    <div className="item-stats-left-top">
                      <p><strong>Force :</strong> {selectedNFTItem.force}</p>
                    </div>
                    <div className="item-stats-left-bottom">
                      <p><strong>Endurance :</strong> {selectedNFTItem.endurance}</p>
                    </div>
                  </div>
                  <div className="item-stats-right">
                    <div className="item-stats-right-top">
                      <p><strong>Agilité :</strong> {selectedNFTItem.agility}</p>
                    </div>  
                    <div className="item-stats-right-bottom">
                      <p><strong>Intelligence :</strong> {selectedNFTItem.intelligence}</p>
                    </div>  
                  </div>
                </div>
                <div className='item-footer'>
                 
                  <h4 className="item-name"><strong>Éco-score :</strong> {selectedNFTItem.eco_score}</h4>
                  {/* Affiche la défense si le type est 'armure' */}
                  {selectedType === 'armure' ? (
                    <h4 className="item-name"><strong>Défense :</strong> {selectedNFTItem.defense}</h4>
                  ) : (
                    // Affiche l'attaque si le type est 'arme'
                    <h4 className="item-name"><strong>Attaque :</strong> {selectedNFTItem.attack}</h4>
                  )}


                  
                </div>
              </div>
            </div>
          </div>
          <div className='item-actions'>
            <div className='item-buttons'>
              <button onClick={regenerateItem} >
                Régénérer ({state.generateCount})
              </button>
              {selectedType === "armure" ? (
                  <button className='bp_mint_item' onClick={() => mintArmure(selectedNFTItem)}>Mint Armure</button>
                ) : (
                  <button className='bp_mint_item'  onClick={() => mintArme(selectedNFTItem)}>Mint Arme</button>
                )}
            </div>
            <div className='item-buttons'>
              <button onClick={onClose}>Annuler</button>
            </div>
          </div>
        </div>
      ) : (
        <p>Chargement de l'item...</p>
      )}
    </div>

  );
};

export default ModalGenerateItem;
