import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { url_backend } from '../config';
import DiceComponent from './DiceComponent';
import { loadBlockchainAndBackendData } from '../services/loadBlockchainAndBackendDataService';
import { connectWallet } from '../services/blockchainService';
import Combat from './Combat';

const Story = ({ playerId, dispatch, currentChapter, storyData, playerSelection, account, state, infosCharacterForPlay, weaponCharacterSelectedForBattle, armorCharacterSelectedForBattle }) => {
  const [diceTask, setDiceTask] = useState(null);
  const [message, setMessage] = useState('');
  const [isCombatTriggered, setIsCombatTriggered] = useState(false);
  const [combatData, setCombatData] = useState(null);

  const [isStoryCompleted, setIsStoryCompleted] = useState(false); // Nouvel état pour gérer la fin de l'histoire


  // Définition de loadCurrentChapter
  const loadCurrentChapter = async () => {
    if (!playerId) {
        console.warn('playerId is not available yet.');
        return;
      }
      console.log('loadCurrentChapter called with playerId:', playerId);
      
    try {
      const response = await axios.get(`${url_backend}/story/current-chapter/${playerId}`);

      if (response.data.storyCompleted) {
        setIsStoryCompleted(true);
        return;
      }

      if (response.data && response.data.chapter) {
        dispatch({ type: 'SET_CURRENT_CHAPTER', payload: response.data.chapter });
      }
      if (response.data && response.data.choices) {
        dispatch({ type: 'SET_STORY_DATA', payload: { chapter: response.data.chapter, choices: response.data.choices } });
      }
      if (response.data && response.data.progress) {
        dispatch({ type: 'SET_ECO_SCORE', payload: response.data.progress.eco_score });
      }

      // Gestion du combat
      if (response.data.combat) {
        setDiceTask(null);
        setCombatData(response.data);
        dispatch({ type: 'SET_CURRENT_COMBAT', payload: response.data });
        setIsCombatTriggered(true);
      } else {
        setIsCombatTriggered(false);
      }
    } catch (error) {
      console.error('Erreur lors du chargement du chapitre actuel:', error);
    }
  };

  useEffect(() => {
    if (playerId) {
      loadCurrentChapter();
    }
  }, [playerId]);

  const handleCombatEnd = async (result) => {
    if (result === 'victory') {
      await loadCurrentChapter();
    } else {
      setMessage('Vous avez été vaincu. Vous devez attendre 4 heures avant de pouvoir rejouer.');
    }
    setIsCombatTriggered(false);
  };

  // Gestion du choix et activation du dé si nécessaire
  const handleChoice = async (choice) => {
    if (choice.requires_dice_roll) {
      setMessage(choice.option_text);
      setDiceTask({
        required_roll: choice.required_roll,
        onSuccess: async () => {
          alert('Vous avez réussi le jet de dé !');
          await submitChoice(choice.choice_id);
        },
        onFailure: () => {
          alert('Échec du jet de dé. Réessayez !');
        },
      });
    } else {
      await submitChoice(choice.choice_id);
    }
  };

  const handleDiceRollComplete = (finalResult) => {
    if (diceTask && finalResult >= diceTask.required_roll) {
      diceTask.onSuccess();
    } else if (diceTask) {
      diceTask.onFailure();
    }
  };

  // Envoyer le choix au backend et déclencher un combat si nécessaire
  const submitChoice = async (choiceId) => {
    try {
      const response = await axios.post(`${url_backend}/story/make-choice`, {
        player_id: playerId,
        choice_id: choiceId,
        character_id: infosCharacterForPlay.character_id,
      });

      // Déclencher un combat si nécessaire
      if (response.data.combat) {
        setDiceTask(null);
        setCombatData(response.data);
        dispatch({ type: 'SET_CURRENT_COMBAT', payload: response.data });
        setIsCombatTriggered(true);
        return;
      }

      // Vérifier si l'histoire est terminée après le choix
      if (response.data.storyCompleted) {
        setIsStoryCompleted(true);
        return;
      }

      // Mettre à jour le chapitre, les choix et l'éco-score
      if (response.data.chapter) {
        dispatch({ type: 'SET_CURRENT_CHAPTER', payload: response.data.chapter });
      }
      if (response.data.choices) {
        dispatch({ type: 'SET_STORY_DATA', payload: { chapter: response.data.chapter, choices: response.data.choices } });
      }
      if (response.data.progress) {
        dispatch({ type: 'SET_ECO_SCORE', payload: response.data.progress.eco_score });
      }

      setDiceTask(null);
      const connectedAccount = await connectWallet();
      await loadBlockchainAndBackendData(connectedAccount, dispatch);
    } catch (error) {
      console.error('Erreur lors de la sélection du choix:', error);
    }
  };

  const handleStartNextSeason = async () => {
    try {
      const response = await axios.post(`${url_backend}/story/start-next-season`, {
        player_id: playerId,
      });
  
      if (response.data && response.data.progress) {
        // Réinitialiser l'état et charger le nouveau chapitre
        setIsStoryCompleted(false);
        await loadCurrentChapter();
      } else {
        alert('Aucune saison suivante disponible.');
      }
    } catch (error) {
      console.error('Erreur lors du démarrage de la saison suivante:', error);
      alert('Une erreur est survenue lors du démarrage de la saison suivante.');
    }
  };

  if (isStoryCompleted) {
    return (
      <div className="story-container">
        <h2>Vous avez terminé la saison !</h2>
        <p>Félicitations pour avoir terminé la saison actuelle.</p>
        {/* Si une nouvelle saison est disponible, vous pouvez ajouter un bouton pour la démarrer */}
    <button onClick={handleStartNextSeason}>Commencer la saison suivante</button>
        {/* Si une nouvelle saison est disponible, vous pouvez ajouter un bouton pour la démarrer */}
        {/* <button onClick={handleStartNextSeason}>Commencer la saison suivante</button> */}
      </div>
    );
  }

  return (
    <div className="story-container">
      {isCombatTriggered ? (
        <Combat 
          playerId={playerId} 
          dispatch={dispatch} 
          currentCombat={combatData} 
          infosCharacterForPlay={infosCharacterForPlay}
          weaponCharacterSelectedForBattle={weaponCharacterSelectedForBattle}
          armorCharacterSelectedForBattle={armorCharacterSelectedForBattle}
          onCombatEnd={handleCombatEnd}
        />
      ) : (
        <>
          <h2>{currentChapter?.title}</h2>
          <p>{currentChapter?.description}</p>

          {message && <p>{message}</p>}

          <div className="choices-container">
            {storyData.choices.map(choice => {
              if (choice.requires_dice_roll && diceTask) {
                return (
                  <div key={choice.choice_id} className='dice_content_centre'>
                    <p>{message}</p>
                    <DiceComponent onRollComplete={handleDiceRollComplete} />
                  </div>
                );
              } else {
                return (
                  <button
                    key={choice.choice_id}
                    onClick={() => handleChoice(choice)}
                  >
                    {choice.option_text}
                  </button>
                );
              }
            })}
          </div>

          <div className="eco-score">
            {infosCharacterForPlay.attributes && infosCharacterForPlay.attributes.ecoScore ? (
              <h4>Eco-Score: {infosCharacterForPlay.attributes.ecoScore}</h4>
            ) : (
              <h4>Eco-Score: 0</h4>
            )}
          </div>
        </>
      )}
    </div>
  );
};

export default Story;
