import React, { useState  } from 'react';
import ModalGenerateItem from './ModalGenerateItem';
import ItemModal from './ItemModal';
//import { loadBlockchainAndBackendData } from '../services/loadBlockchainAndBackendDataService';

import {  timeoutItemCharacter } from '../config';

import axios from 'axios';
import {  connectWallet  } from '../services/blockchainService';

const InventoryModal = ({ selectedType, dispatch,  connectedAccount, onClose, state, nft, loadBlockchainAndBackendData }) => {
  const [isItemModalOpen, setIsItemModalOpen] = useState(false);  // État pour le modal d'items
  const [items, setItems] = useState([]);  // Stocke les items à afficher (armures ou armes)


 
  //const [selectedWeapons, setSelectedWeapons] = useState([]);
  //const [selectedArmors, setSelectedArmors] = useState([]);
  
  const openGenerateModal = () => {
    dispatch({ type: 'SET_IS_GENERATING_ITEM', payload: true });
  };

  // Fermer le modal de génération d'item
  const closeGenerateModal = () => {
    dispatch({ type: 'SET_IS_GENERATING_ITEM', payload: false });
    dispatch({ type: 'SET_GENERATE_COUNT', payload: 5 }); // Réinitialiser le nombre de régénérations
  };

  const handleMintSuccessItem = async () => {
    //console.log("handleMintSuccessItem called");
    if (!connectedAccount) {
      connectedAccount = await connectWallet();
      
    }
    await loadBlockchainAndBackendData(connectedAccount, dispatch); // Notez que loadBlockchainAndBackendData prend dispatch en second
     
    setTimeout(() => {
     
      dispatch({ type: 'SET_IS_LOADING', payload: false });
      
    }, timeoutItemCharacter); 
  };
 
 
  const openListItem = async (selectedType) => {
   
    await handleMintSuccessItem();
  //  console.log("selectedType", selectedType);
  
    let itemList = [];
    let inventoryLabel = '';
      if (selectedType === "armure") {
      itemList = state.nftsArmures;

      inventoryLabel = 'armure';
    } else if (selectedType === "arme") {
      itemList = state.nftsArmes;
      inventoryLabel = 'arme';
    }
    //console.log(`Items list (${selectedType}):`, itemList);
  //  console.log(`Liste des ${inventoryLabel} récupérée :`, itemList);
  
 
    if (!itemList || itemList.length === 0) {
      alert(`Inventaire ${inventoryLabel} vide`);
      return;
    }
    
   
    setItems(itemList);
    //console.log("--------------------Items set in InventoryModal:", items); // <-- Ajoute ce log ici

    setIsItemModalOpen(true);
   
  };
  
  

  const handleSelectItem = async (type, item) => {
   // console.log("Type Item sélectionné :", type);
   // console.log("Item sélectionné :", item);
   // console.log("personnage : ", nft);

    dispatch({ type: 'SET_IS_LOADING', payload: true });
   
    const characterId = nft.character_id;

    if (!characterId) {
      console.error("Aucun personnage sélectionné.");
      dispatch({ type: 'SET_IS_LOADING', payload: false });
      return;
    }

    try {
      const payload = {
        character_id: characterId,
        weapon_id: type === 'arme' ? item.weapon_id : null,
        armor_id: type === 'armure' ? item.armor_id : null,
      };
    //  console.log("Données envoyées à l'API :", payload);

      const response = await axios.post('/api/inventory/select-equipment', payload);

      if (response.data.success) {
        const updatedNft = {
          ...nft,
          weapon_id: payload.weapon_id,
          armor_id: payload.armor_id,
        };

       // console.log("updatedNft dans InventoryModal: ", updatedNft);

        onClose(updatedNft); // Passez l'objet mis à jour

        closeItemModal();
      } else {
        dispatch({ type: 'SET_IS_LOADING', payload: false });
        console.error("Erreur lors de la sélection de l'équipement :", response.data.message);
      }
    } catch (error) {
      dispatch({ type: 'SET_IS_LOADING', payload: false });
      console.error('Erreur lors de la sélection de l\'équipement:', error);
    }
};


 
  /*
  
  useEffect(() => {
  const fetchSelectedEquipment = async () => {
    try {
      const response = await axios.get('/api/inventory/selected-equipment');
      setSelectedWeapons(response.data.selectedWeapons);
      setSelectedArmors(response.data.selectedArmors);
    } catch (error) {
      console.error('Erreur lors de la récupération des équipements sélectionnés:', error);
    }
  };
  fetchSelectedEquipment();
}, []);
  
  */
  const closeItemModal = async () => {
   
    dispatch({ type: 'SET_IS_LOADING', payload: true });
    setIsItemModalOpen(false);
   // console.log("handleMintSuccessItem")
  
    await handleMintSuccessItem();
    dispatch({ type: 'SET_IS_LOADING', payload: false });
  };
 
  return (
    <div className="modal-container-inventory">
      <div className='inventaire-container'>
        <h2>{selectedType === 'armure' ? 'Armure' : 'Arme'}</h2>
        <button onClick={() => openListItem(selectedType)}>Afficher Inventaire</button>
        <div className="modal-actions">
          <button onClick={openGenerateModal}>Générer {selectedType === 'armure' ? 'Armure' : 'Arme'}</button>
          <button onClick={onClose}>Fermer</button>
        </div>
      </div>
      <div className='generate-container'>
        {state.isGeneratingItem && (
          <ModalGenerateItem selectedType={selectedType} dispatch={dispatch} state={state} connectedAccount={connectedAccount} handleMintSuccessItem={handleMintSuccessItem} onClose={closeGenerateModal} />
        )}
      </div>

      {/* Afficher le modal des items (armures ou armes) */}
     

      {isItemModalOpen && (
        <ItemModal
          items={items}
          onClose={closeItemModal}
          onSelect={handleSelectItem}
          type={selectedType}
        />
      )}
    </div>
  );
};

export default InventoryModal;