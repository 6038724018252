// src/components/BuyAngelus.js
import React, { useEffect } from 'react';
import { buyAngelusTokens } from '../services/buyAngelusService';

const BuyAngelus = ({ dispatch, state, onClose }) => {
  const { amountAngelus, priceInMatic, errorMessage } = state;

  // Fonction pour calculer le prix en MATIC en fonction du montant d'Angelus
  useEffect(() => {
    const angelusToMaticRate = 1000; // 1 MATIC = 1000 Angelus tokens
    const calculatedPrice = amountAngelus / angelusToMaticRate;
    dispatch({ type: 'SET_PRICE_IN_MATIC', payload: calculatedPrice });
  }, [amountAngelus, dispatch]);

  // Fonction pour gérer l'achat d'Angelus tokens
  const handleBuy = async () => {
    dispatch({ type: 'SET_IS_LOADING', payload: true });
    if (!amountAngelus || parseFloat(amountAngelus) <= 0) {
      dispatch({ type: 'SET_ERROR_MESSAGE', payload: 'Veuillez entrer un montant valide d\'Angelus.' });
      return;
    } 
    dispatch({ type: 'SET_ERROR_MESSAGE', payload: '' });
 
    try {
      await buyAngelusTokens(amountAngelus);
      onClose(dispatch);
    } catch (error) {
      console.error('Erreur lors de l\'achat de tokens Angelus:', error);
      dispatch({ type: 'SET_ERROR_MESSAGE', payload: 'Erreur lors de l\'achat. Veuillez réessayer.' });
    }
  };  
 
  return (
    <div className="modalBuyAngelus">
      <div className="modal-content">
        <h3>Acheter des Angelus Tokens</h3>
        <div className="form-group">
          <label>Montant d'Angelus</label>
          <input
            type="number"
            value={amountAngelus}
            onChange={(e) => dispatch({ type: 'SET_AMOUNT_ANGELUS', payload: e.target.value })}
            placeholder="Entrez le montant d'Angelus"
          />
        </div>
        <div className="form-group">
          <label>Prix en MATIC: {priceInMatic.toFixed(4)} MATIC</label>
        </div>
        {errorMessage && <p className="error-message">{errorMessage}</p>}
        <div className="button-group">
          <button className="buy-button" onClick={handleBuy}>Acheter</button>
          <button className="close-button" onClick={() => onClose(dispatch)}>Fermer</button>
        </div>
      </div>
    </div>
  );
}; 

export default BuyAngelus;
