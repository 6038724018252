import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import { url_backend } from '../config';

const ItemModal = ({ items, onClose, onSelect, type }) => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [showAttributes, setShowAttributes] = useState(false); // État pour contrôler l'affichage des attributs
  const itemCardRef = useRef(null);
  const [tooltipVisible, setTooltipVisible] = useState(false); // État pour afficher le tooltip
  const [equipmentAssigned, setEquipmentAssigned] = useState([]);
 

  useEffect(() => {
    // Récupérer la liste des équipements déjà assignés
    const fetchAssignedEquipment = async () => {
      try {
    
        const response = await axios.get(`${url_backend}/inventory/equipment-assigned`);
     
        setEquipmentAssigned(response.data.equipmentAssigned);
      } catch (error) {
        console.error('Erreur lors de la récupération des équipements assignés:', error);
      }
    };
    fetchAssignedEquipment();
  }, []);
  
  const prevItem = () => {
    if (currentIndex > 0) {
      setCurrentIndex(currentIndex - 1);
    }
  };
 
  const nextItem = () => {
    if (currentIndex < items.length - 1) {
      setCurrentIndex(currentIndex + 1);
    }
  };
 
  const currentItem = items[currentIndex];
  //console.log("Current item in ItemModal:", currentItem);


 
  const toggleAttributes = () => {
    //console.log(currentItem.rarity.toLowerCase())
    setShowAttributes(!showAttributes); // Inverser l'état d'affichage
  };

  // Gestion du clic à l'extérieur pour masquer les attributs
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (itemCardRef.current && !itemCardRef.current.contains(event.target)) {
        setShowAttributes(false); // Masquer les attributs si on clique à l'extérieur
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [itemCardRef]);

  // Vérification si l'item est déjà sélectionné
  const isItemSelected = () => {
    if (type === 'arme') {
      return equipmentAssigned.some((eq) => eq.weapon_id === currentItem.weapon_id);
    }
    if (type === 'armure') {
      return equipmentAssigned.some((eq) => eq.armor_id === currentItem.armor_id);
    }
    return false;
  };



  // Fonction pour gérer le clic sur le bouton sélectionné
  const handleSelectClick = () => {
    if (isItemSelected()) {
      setTooltipVisible(true);
      setTimeout(() => setTooltipVisible(false), 3000); 
    } else {
      onSelect(type, currentItem);
    }
  };


  return (
    <div className="modal-container-item">
      <div className="item-display">
        <div className="item-card" ref={itemCardRef}>
          <h4>{currentItem.name}</h4>
          <h3 className={`item-name-rarity ${currentItem.rarity.toLowerCase()}`}>
            {currentItem.rarity.toString()}
           </h3>
          

           

          {/* Superposition des attributs sur l'image */}
          <div className="item-image-container" onClick={toggleAttributes}>
            <img src={currentItem.imageFront} alt={currentItem.name} style={{ width: '150px' }} />
            {showAttributes && ( // Afficher les attributs si showAttributes est true
              <div className="item-attributes-overlay">
                {/* Type d'item (Armure ou Arme) */}
                {type === 'armure' ? (
                    <p>Type: {currentItem.armureType}</p>
                ) : (
                    <p>Type: {currentItem.armeType}</p>
                )}
                {/* Défense ou Attaque */}
                {type === 'armure' ? (
                    <p>Défense: {currentItem.defense.toString()}</p>
                ) : (
                    <p>Attaque: {currentItem.attack.toString()}</p>
                )}
                <p>Force: {currentItem.force.toString()}</p>
                <p>Endurance: {currentItem.endurance.toString()}</p>
                <p>Agilité: {currentItem.agility.toString()}</p>
                <p>Intelligence: {currentItem.intelligence.toString()}</p>
                <p>Eco-score: {currentItem.ecoscore.toString()}</p>
              </div>
            )}
          </div>

          {/* Boutons de sélection et de mise à jour */}
          <div className="item-actions">
            <button
                onClick={handleSelectClick}
                disabled={isItemSelected()}
                className={`select-button ${isItemSelected() ? 'disabled' : ''}`}
                >
                {isItemSelected() ? 'Déjà sélectionné' : 'Sélectionner'}
            </button>
            {tooltipVisible && <div className="tooltip">Cet item est déjà sélectionné par un autre personnage.</div>}
            
            <button>Mise à jour</button>
          </div>
        </div>
      </div>

      {/* Navigation entre les items */}
      <div className="item-navigation">
        <button onClick={prevItem} disabled={currentIndex === 0}>Précédent</button>
        <button onClick={nextItem} disabled={currentIndex === items.length - 1}>Suivant</button>
      </div>

      <button onClick={onClose}>Fermer</button>
    </div>
  );
};

export default ItemModal;
