import { connectWallet, web3 } from './blockchainService';
import { loadBlockchainAndBackendData } from './loadBlockchainAndBackendDataService';

// Gérer la connexion au wallet
export const handleConnectWallet = async (dispatch) => {
  if (!web3) {
    window.alert('Veuillez installer MetaMask pour utiliser cette application.');
    return;
  }

  const connectedAccount = await connectWallet();
  if (connectedAccount) {
    dispatch({ type: 'SET_ACCOUNT', payload: connectedAccount });
    await loadBlockchainAndBackendData(connectedAccount, dispatch);
  }
}; 

// Gérer les changements de compte MetaMask
export const handleAccountChanged = async (accounts, dispatch) => {
  if (accounts.length > 0) {
    dispatch({ type: 'SET_ACCOUNT', payload: accounts[0] });
    dispatch({ type: 'SET_SHOW_MODAL', payload: false });
    dispatch({ type: 'SET_SHOW_MODAL_DETAIL', payload: false });
    dispatch({ type: 'SET_SHOW_MODAL_BUY_ANGELUS', payload: false });
    dispatch({ type: 'SET_IS_GENERATING_ITEM', payload: false });
    dispatch({ type: 'SET_SHOW_MODAL_BUY_ANGELUS', payload: false });
    dispatch({ type: 'SET_SHOW_MODAL_BUY_ANGELUS', payload: false });
    await loadBlockchainAndBackendData(accounts[0], dispatch);
  } else {
    dispatch({ type: 'SET_ACCOUNT', payload: '' });
    dispatch({ type: 'SET_NFTS', payload: [] });
    dispatch({ type: 'SET_BALANCE', payload: '0' });
    dispatch({ type: 'SET_NFT_BALANCE', payload: '0' });
  }
};
      
// Ajoutez un écouteur de changement d'état MetaMask
export const addMetaMaskListeners = (handleAccountChanged) => {
  if (window.ethereum) {
    window.ethereum.removeListener('accountsChanged', handleAccountChanged);
    window.ethereum.on('accountsChanged', handleAccountChanged);
    window.ethereum.on('chainChanged', () => window.location.reload());
  }
};
 