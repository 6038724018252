import React, { useState, useEffect } from 'react';

import axios from 'axios';
import { url_backend , intervallUpdateCountdownBattle } from '../config';
import { calculateTimeRemainingAndProgress } from '../services/outils';
import InventoryModal  from './InventoryModal';
import {  connectWallet  } from '../services/blockchainService';
import {  fetchArmorDetails, fetchWeaponDetails  } from '../services/outils';

const DetailCharacter = ({ nft, onClose, playerId, /*onCharacterSelected,*/ dispatch, state, connectedAccount, loadBlockchainAndBackendData  }) => {
  const [isFlipped, setIsFlipped] = useState(false);
  const [timeRemaining, setTimeRemaining] = useState('');
  const [progress, setProgress] = useState(100);

  const [isInventoryModalOpen, setIsInventoryModalOpen] = useState(false);
  const [selectedType, setSelectedType] = useState(null); // 'armure' ou 'arme'
  
  const [armorDetails, setArmorDetails] = useState(null);
  const [weaponDetails, setWeaponDetails] = useState(null);

  const [isCharacterSelected, setIsCharacterSelected] = useState(false); // Nouveau state pour vérifier si le personnage est déjà sélectionné

  useEffect(() => {
   // console.log(nft);

    // Vérifier si l'armor_id existe dans l'objet nft et récupérer les détails de l'armure
    if (nft.armor_id) {
      fetchArmorDetails(nft.armor_id).then((data) => {
      //  console.log("data armor", data);
        setArmorDetails(data); // Stocker les détails de l'armure dans l'état
      });
    }

    // Vérifier si le weapon_id existe dans l'objet nft et récupérer les détails de l'arme
    if (nft.weapon_id) {
      fetchWeaponDetails(nft.weapon_id).then((data) => {
      //  console.log("data weapon ", data);
        setWeaponDetails(data); // Stocker les détails de l'arme dans l'état
      });
    }

    // Vérifier si le personnage est déjà sélectionné
    const checkIfCharacterSelected = () => {
      const selectedNft = state.selectedNftForBattle;
      if (selectedNft && selectedNft.character_id === nft.character_id) {
        setIsCharacterSelected(true);
      } else {
        setIsCharacterSelected(false);
      }
    };

    checkIfCharacterSelected();
  }, [nft, state.selectedNftForBattle]);

  // Gérer le retournement de la carte
  const handleCardFlip = () => setIsFlipped(!isFlipped);
 
  // Calcul du temps restant et de la progression pour le prochain combat
  useEffect(() => {
    const updateCountdown = () => {
    //  console.log("nft", nft)
      const { timeRemaining, progress } = calculateTimeRemainingAndProgress(nft.attributes.next_combat_time);
      setTimeRemaining(timeRemaining);
      setProgress(progress);
    };
 
    const interval = setInterval(updateCountdown, intervallUpdateCountdownBattle);
    return () => clearInterval(interval);
  }, [nft.nextCombatTime]);
 
  // Fonction pour sélectionner le personnage
  const selectPerso = async () => {
    dispatch({ type: 'SET_SHOW_MODAL', payload: true });
   
    if (!playerId || !nft.character_id) {
      alert("Player ID ou character_id manquant !");
      return;
    }
    try {
      const response = await axios.post(`${url_backend}/characters/select`, {
        player_id: playerId,
        character_id: nft.character_id,
      });

      if (response.data.success) {
        dispatch({ type: 'SET_SHOW_MODAL', payload: false });
        alert('Personnage sélectionné avec succès');
        dispatch({ type: 'SET_SELECTED_NFT', payload: nft });
       // onCharacterSelected(nft);
        onClose();
      } else {
        dispatch({ type: 'SET_SHOW_MODAL', payload: false });
        alert('Erreur lors de la sélection du personnage');
      }
    } catch (error) {
      console.error('Erreur lors de la sélection du personnage:', error);
      dispatch({ type: 'SET_SHOW_MODAL', payload: false });
      alert('Une erreur est survenue');
    }
  };

  // Fonction pour rendre les aptitudes raciales et les actions de classe
  const renderList = (items, limit = 3) => (
    <ul>
      {console.log("item dans renderList: ", items)}
      {items && Object.values(items).slice(0, limit).map((item, index) => (
        item.name && item.points ? (
          <li key={index}>{item.name} : {item.points.toString()}</li>
        ) : null
      ))}
    </ul>
  );

  const selectArmure = () => {
    setSelectedType('armure');
    setIsInventoryModalOpen(true);
  };
  
  const selectArme = () => {
    setSelectedType('arme');
    setIsInventoryModalOpen(true);
  };
  
  const closeInventory = async (updatedNft = null) => {
    if (!connectedAccount) {
      connectedAccount = await connectWallet();
      
    }
    //const updatedNFTS = await loadBlockchainAndBackendData(connectedAccount, dispatch);
    await loadBlockchainAndBackendData(connectedAccount, dispatch);
    setTimeout(() => {
      dispatch({ type: 'SET_IS_LOADING', payload: false });
      setIsInventoryModalOpen(false)
    
    
      //console.log("updatedNFTS",updatedNFTS)
      // Si un `nft` mis à jour est passé depuis le modal
        if (updatedNft) {
      //   console.log("updatedNft dans DetailCharacter: ", updatedNft);

          // Vérifiez si updatedNft a les ids avant de faire les requêtes
          if (updatedNft.armor_id) {
            fetchArmorDetails(updatedNft.armor_id).then((data) => {
              setArmorDetails(data);
            });
          }

          if (updatedNft.weapon_id) {
            fetchWeaponDetails(updatedNft.weapon_id).then((data) => {
              setWeaponDetails(data);
            });
          }
        }
        dispatch({ type: 'SET_IS_LOADING', payload: false });
      }, 1000); 

    }
  
  return (
    <div className="modal-container-detail">
      <div className="card-container">
        <div className={`card ${isFlipped ? 'flipped' : ''}`} onClick={handleCardFlip}>
          <div className="card-front">
            <img src={nft.imageFront} alt="Personnage NFT" />
            <div className="character-info">
              <div className='character-header'>
                <h2 className="character-name">{nft.name}</h2>
                <h4 className="character-gender">{nft.gender}</h4>
              </div>
              <div className='character-body'>
                <div className="character-stats-left">
                  <p>{nft.attributes.size.toString()} cm</p>
                  <p>{nft.attributes.weight.toString()} kg</p>
                </div>
                <div className="character-stats-right">
                  <p><strong>Endurance :</strong> {nft.attributes.endurance.toString()}</p>
                  <p><strong>Force :</strong> {nft.attributes.force.toString()}</p>
                  <p><strong>Agilité :</strong> {nft.attributes.agility.toString()}</p>
                  <p><strong>Intelligence :</strong> {nft.attributes.intelligence.toString()}</p>
                  <p><strong>EcoScore :</strong> {nft.attributes.ecoScore.toString()}</p>
                </div>
              </div>
              <div className='character-footer'>
                <h4 className="character-race">{nft.race}</h4>
                <h4 className="character-class">{nft.class}</h4>
              </div>
            </div>
          </div>
          <div className="card-back">
            <img src={nft.imageBack} alt="Fond NFT" />
            <div className="abilities-info">
              <div className='abilities-info-raciale'>
                <h4>Aptitudes raciales :</h4>
                {renderList(nft.raceAbilities)}
              </div>
              <div className='abilities-info-class'>
                <h4>Actions de classe :</h4>
                <div className='abilities-info-class-attack'>
                  <h5>Attaque :</h5>
                  {renderList(nft.classActions.classActionsAttack)}
                </div>
                <div className='abilities-info-class-defense'>
                  <h5>Défense :</h5>
                  {renderList(nft.classActions.classActionsDefense)}
                </div>
                <div className='abilities-info-class-heal'>
                  <h5>Soin :</h5>
                  {renderList(nft.classActions.classActionsHeal)}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div> 
       
      {/* Sélection du personnage */}
      <button
        onClick={selectPerso}
        className={`select-perso ${isCharacterSelected ? 'disabled' : ''}`}
        disabled={isCharacterSelected}
      >
        {isCharacterSelected ? 'Personnage déjà sélectionné' : 'Sélectionner Personnage'}
      </button>


      {/* Barre de décompte */}
      <div className="combat-timer">
      <h4>
        {timeRemaining === 'Prêt pour le combat' 
          ? 'Prêt pour le combat' 
          : `Prochain combat dans : ${timeRemaining}`}
      </h4>
        <div className="progress-bar-container">
          <div className="progress-bar" style={{ width: `${progress}%` }}></div>
        </div>
      </div>

      {/* Inventaire du personnage */}
      <div className='inventaire-perso'>
        <h4>Inventaire</h4>
        <div className='inventaire_container'>
          <div className='armure-perso' onClick={selectArmure}>
            <h5>Armure</h5>      
            <div className='armure-select'>
              {armorDetails ? (
                // Si les détails de l'armure existent, afficher l'image et le texte
                <div className='armure-content'>
                  <img src={armorDetails.image} alt={armorDetails.name} className="armor-image" />
                  <p className='armorDetailsHaut'>{armorDetails.name} </p>
                  <p className='armorDetailsBas'>{armorDetails.rarity}</p>
                </div>
              ) : (
                // Si aucune armure sélectionnée, afficher une icône avec un +
                <div className="empty-slot">
                  <i className="icon-plus">+</i>
                </div>
              )}
            </div>
          </div>
          <div className='arme-perso' onClick={selectArme}>
            <h5>Arme</h5>      
            <div className='arme-select'>
              {weaponDetails ? (
                // Si les détails de l'arme existent, afficher l'image et le texte
                <div className='arme-content'>
                  <img src={weaponDetails.image} alt={weaponDetails.name} className="weapon-image" />
                  <p className='armorDetailsHaut'>{weaponDetails.name} </p>
                  <p className='armorDetailsBas'>{weaponDetails.rarity}</p>
                </div>
              ) : (
                // Si aucune arme sélectionnée, afficher une icône avec un +
                <div className="empty-slot">
                  <i className="icon-plus">+</i>
                </div>
              )}
            </div>
          </div>
        </div>        
      </div>

      {isInventoryModalOpen && (
        <InventoryModal 
          selectedType={selectedType}
          dispatch={dispatch}
          connectedAccount={connectedAccount}
          onClose={closeInventory}
          state={state}
          nft={nft}
          loadBlockchainAndBackendData={loadBlockchainAndBackendData}
        />
      )}

      <button onClick={onClose} className="close-button">Fermer</button>
    </div>
  );
};

export default React.memo(DetailCharacter);
