// src/services/storyService.js
import axios from 'axios';
import { url_backend } from '../config';

/**
 * Récupère le chapitre actuel de l'histoire pour le joueur.
 * @param {number} playerId - L'ID du joueur.
 * @returns {Object} Les données du chapitre actuel.
 */
export const fetchCurrentChapter = async (playerId) => {
    try {
        const response = await axios.get(`${url_backend}/story/current-chapter/${playerId}`);
        return response.data;
    } catch (error) {
        console.error('Erreur lors du chargement du chapitre actuel:', error);
        throw error;
    }
};
 
/**
 * Démarre l'histoire pour le joueur en initialisant le premier chapitre.
 * @param {number} playerId - L'ID du joueur.
 * @returns {Object} Les données du premier chapitre.
 */
export const startStory = async (playerId) => {
    try {
        const response = await axios.post(`${url_backend}/story/start`, {
            player_id: playerId,
        });
        return response.data;
    } catch (error) {
        console.error('Erreur lors du démarrage de l\'histoire:', error);
        throw error;
    }
};
   
/**
 * Enregistre le choix effectué par le joueur dans l'histoire.
 * @param {number} playerId - L'ID du joueur.
 * @param {number} choiceId - L'ID du choix effectué.
 * @returns {Object} Les données mises à jour après le choix.
 */
export const makeChoice = async (playerId, choiceId) => {
    try {
        const response = await axios.post(`${url_backend}/story/make-choice`, {
            player_id: playerId,
            choice_id: choiceId,
        });
        return response.data;
    } catch (error) {
        console.error('Erreur lors de la sélection du choix:', error);
        throw error;
    }
};
