import React, { useState } from 'react';
import PropTypes from 'prop-types';

// Composant Accordion
const Accordion = ({ title, children }) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleAccordion = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div className="accordion-container">
      <div className="accordion-title" onClick={toggleAccordion}>
        <h3>{title}</h3>
        <span>{isOpen ? '-' : '+'}</span>
      </div>
      {isOpen && <div className="accordion-content">{children}</div>}
    </div>
  );
};

Accordion.propTypes = {
  title: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
};


// Définir la fonction handleClickAction
/*const handleClickAction = (action) => {
    alert(`Action: ${action.name}\nPoints: ${action.points.toString()}`);
  };
  */
// Fonction utilitaire mise à jour
const ensureArray = (data) => {
    if (Array.isArray(data)) return data;
    if (data && typeof data === 'object') {
      return Object.entries(data)
        .filter(([key, value]) => !isNaN(key) && typeof value === 'object' && value !== null && 'name' in value && 'points' in value)
        .map(([key, value]) => value);
    }
    return [];
  };
  
 
// Composant pour afficher les détails du personnage avec React.memo
const CharacterDisplay = React.memo(({ infosCharacterForPlay }) => {
  const [isFlipped, setIsFlipped] = useState(false);

  if (!infosCharacterForPlay) {
    return <p>Chargement des informations du personnage...</p>;
  }

  const {
    name,
    gender,
    race,
    class: characterClass,
    imageFront,
    attributes,
    raceAbilities,
    classActions,
  } = infosCharacterForPlay;

  // Convertir les objets semblables à des tableaux en véritables tableaux
  const raceAbilitiesArray = ensureArray(raceAbilities);
  const classActionsAttackArray = ensureArray(classActions?.classActionsAttack);
  const classActionsDefenseArray = ensureArray(classActions?.classActionsDefense);
  const classActionsHealArray = ensureArray(classActions?.classActionsHeal);
  
  //console.log("++++++++++++++++++++++++raceAbilitiesArray", raceAbilitiesArray);

  const handleFlip = () => {
    setIsFlipped(!isFlipped);
  };

  return (
    <div
      className={`character-card ${isFlipped ? 'flipped' : ''}`}
      onClick={handleFlip}
      role="button"
      tabIndex={0}
      onKeyPress={(e) => {
        if (e.key === 'Enter' || e.key === ' ') {
          handleFlip();
        }
      }}
      aria-pressed={isFlipped}
    >
      <div className="character-card-inner">
        {/* Face Avant */}
        <div className="character-card-front character-infos">
          <h2 className="character-name">
            {name} ({gender})
          </h2>
          <h4 className="character-race">
            {race} / {characterClass}
          </h4>
          <div className="character-images">
            {imageFront && (
              <img
                className="img_personnage_front"
                src={imageFront}
                alt="Personnage NFT - Avant"
              />
            )}
          </div>
          <div className="character-attributes">
            <ul>
              <li>{attributes?.size?.toString()} Cm / {attributes?.weight?.toString()} Kg</li>
              <li>Endurance: {attributes?.endurance?.toString()}</li>
              <li>Force: {attributes?.force?.toString()}</li>
              <li>Intelligence: {attributes?.intelligence?.toString()}</li>
              <li>Agilité: {attributes?.agility?.toString()}</li>
              <li>Eco Score: {attributes?.ecoScore?.toString()}</li>
              <li>Vie: {attributes?.health?.toString()}</li>
            </ul>
            <h3>Compétences de Race</h3>
            <ul>
              {raceAbilitiesArray.length > 0 ? (
                raceAbilitiesArray.map((ability, index) => (
                  <li key={index}>
                    {ability.name} : {ability.points.toString()}
                  </li>
                ))
              ) : (
                <li>Aucune compétence de race disponible.</li>
              )}
            </ul>
          </div>
        </div>
        
        {/* Face Arrière */}
        <div className="character-card-back">
          <div className="character-class-actions">
         
            <div className="actions-section">
              <h4>Attaques</h4>
              <ul>
                {classActionsAttackArray.length > 0 ? (
                  classActionsAttackArray.map((action, index) => (
                    <li key={`${action.name}-${index}`}>
                      
                        {action.name} : {action.points.toString()}
                      
                    </li>
                  ))
                ) : (
                  <li>Aucune attaque disponible.</li>
                )}
              </ul>
            </div>
            <div className="actions-section">
              <h4>Défenses</h4>
              <ul>
                {classActionsDefenseArray.length > 0 ? (
                  classActionsDefenseArray.map((action, index) => (
                    <li key={`${action.name}-${index}`}>
                      
                        {action.name} : {action.points.toString()}
                     
                    </li>
                  ))
                ) : (
                  <li>Aucune défense disponible.</li>
                )}
              </ul>
            </div>
            <div className="actions-section">
              <h4>Soins</h4>
              <ul>
                {classActionsHealArray.length > 0 ? (
                  classActionsHealArray.map((action, index) => (
                    <li key={`${action.name}-${index}`}>
                     
                        {action.name} : {action.points.toString()}
                      
                    </li>
                  ))
                ) : (
                  <li>Aucun soin disponible.</li>
                )}
              </ul>
            </div>
          </div>
          
        </div>
      </div>
    </div>
  );
});

CharacterDisplay.propTypes = {
  infosCharacterForPlay: PropTypes.shape({
    name: PropTypes.string.isRequired,
    gender: PropTypes.string,
    race: PropTypes.string,
    class: PropTypes.string,
    image_front: PropTypes.string,
    attributes: PropTypes.shape({
      size: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
      weight: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
      endurance: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
      force: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
      intelligence: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
      agility: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
      ecoScore: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    }),
    raceAbilities: PropTypes.oneOfType([
      PropTypes.arrayOf(
        PropTypes.shape({
          name: PropTypes.string.isRequired,
          points: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
        })
      ),
      PropTypes.object, // pour les objets semblables à des tableaux
    ]),
    classActions: PropTypes.shape({
      classActionsAttack: PropTypes.oneOfType([
        PropTypes.arrayOf(
          PropTypes.shape({
            name: PropTypes.string.isRequired,
            points: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
          })
        ),
        PropTypes.object, // pour les objets semblables à des tableaux
      ]),
      classActionsDefense: PropTypes.oneOfType([
        PropTypes.arrayOf(
          PropTypes.shape({
            name: PropTypes.string.isRequired,
            points: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
          })
        ),
        PropTypes.object, // pour les objets semblables à des tableaux
      ]),
      classActionsHeal: PropTypes.oneOfType([
        PropTypes.arrayOf(
          PropTypes.shape({
            name: PropTypes.string.isRequired,
            points: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
          })
        ),
        PropTypes.object, // pour les objets semblables à des tableaux
      ]),
    }),
  }),
};

// Composant pour afficher les détails de l'armure avec React.memo
const ArmorDisplay = React.memo(({ armor }) => {
    if (!armor) {
      return <p>Chargement de l'armure...</p>;
    }
  
    const {
      name,
      imageFront,
      armureType,
      agility,
      defense,
      ecoscore,
      endurance,
      force,
      intelligence,
      rarity,
      // Ajoutez d'autres propriétés si nécessaire
    } = armor;
  
    return (
      <div className="armure-infos">
        <h3>{name}</h3>
        {imageFront && <img src={imageFront} alt="Armure" className="armor-image" />}
        <ul>
          <li>Type: {armureType}</li>
          <li>Agilité: {agility.toString()}</li>
          <li>Défense: {defense.toString()}</li>
          <li>Eco Score: {ecoscore.toString()}</li>
          <li>Endurance: {endurance.toString()}</li>
          <li>Force: {force.toString()}</li>
          <li>Intelligence: {intelligence.toString()}</li>
         
          <li className={`item-name-rarity ${rarity.toLowerCase()}`}>
            {rarity.toString()}
           </li>
        </ul>
      </div>
    );
  });
  
  ArmorDisplay.propTypes = {
    armor: PropTypes.shape({
      name: PropTypes.string.isRequired,
      imageFront: PropTypes.string,
      armureType: PropTypes.string,
      agility: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
      defense: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
      ecoscore: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
      endurance: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
      force: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
      intelligence: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
      rarity: PropTypes.string,
      
      // Ajoutez d'autres propriétés si nécessaire
    }),
  };

// Composant pour afficher les détails de l'arme avec React.memo
const WeaponDisplay = React.memo(({ weapon }) => {
    if (!weapon) {
      return <p>Chargement de l'arme...</p>;
    }
  
    const {
      name,
      imageFront,
      armeType,
      attack,
      ecoscore,
      endurance,
      force,
      intelligence,
      agility,
      rarity,
      // Ajoutez d'autres propriétés si nécessaire
    } = weapon;
  
    return (
      <div className="arme-infos">

        <h3>{name}</h3>
        {imageFront && <img src={`${imageFront}`} alt="Arme" className="weapon-image" />}
        <ul>
          <li>Type: {armeType}</li>
          <li>Attaque: {attack.toString()}</li>
          <li>Eco Score: {ecoscore.toString()}</li>
          <li>Endurance: {endurance.toString()}</li>
          <li>Force: {force.toString()}</li>
          <li>Intelligence: {intelligence.toString()}</li>
          <li>Agilité: {agility.toString()}</li>
          
          <li className={`item-name-rarity ${rarity.toLowerCase()}`}>
            {rarity.toString()}
           </li>
        </ul>
      </div>
    );
  });
  
  WeaponDisplay.propTypes = {
    weapon: PropTypes.shape({
      name: PropTypes.string.isRequired,
      imageFront: PropTypes.string,
      armeType: PropTypes.string,
      attack: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
      ecoscore: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
      endurance: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
      force: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
      intelligence: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
      agility: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
      rarity: PropTypes.string,
    
      // Ajoutez d'autres propriétés si nécessaire
    }),
  };
 
// Composant principal
const CharacterDetailsPlay = ({
  playerId,
  dispatch,
  playerSelection,
  infosCharacterForPlay,
  weaponCharacterSelectedForBattle,
  armorCharacterSelectedForBattle,
  account,
}) => {
 /* console.log("++++++++++++++++++++++++++++++++++++++++++++++");
  console.log("playerId", playerId);
  console.log("playerSelection", playerSelection);
  console.log("dispatch", dispatch);
  console.log("infosCharacterForPlay", infosCharacterForPlay);
  console.log("weaponCharacterSelectedForBattle", weaponCharacterSelectedForBattle);
  console.log("armorCharacterSelectedForBattle", armorCharacterSelectedForBattle);
  console.log("account", account);
  console.log("++++++++++++++++++++++++++++++++++++++++++++++");
*/ 
  return (
    <div className="charactere-play-container">
      <div className="character-container">
        {infosCharacterForPlay ? (
          <CharacterDisplay infosCharacterForPlay={infosCharacterForPlay} />
        ) : (
          <p>Aucun Personnage sélectionnée</p>
        )}
        
      </div>
      <Accordion title="Armure">
        {armorCharacterSelectedForBattle ? (
          <ArmorDisplay armor={armorCharacterSelectedForBattle} />
        ) : (
          <p>Aucune armure sélectionnée</p>
        )}
      </Accordion>
      {/* Accordion pour l'arme */}
      <Accordion title="Arme">
        {weaponCharacterSelectedForBattle ? (
          <WeaponDisplay weapon={weaponCharacterSelectedForBattle} />
        ) : (
          <p>Aucune arme sélectionnée</p>
        )}
      </Accordion>
    </div>
  );
};

// Définition des PropTypes pour valider les props reçues
CharacterDetailsPlay.propTypes = {
  playerId: PropTypes.string.isRequired,
  dispatch: PropTypes.func.isRequired,
  playerSelection: PropTypes.object,
  infosCharacterForPlay: PropTypes.shape({
    name: PropTypes.string.isRequired,
    gender: PropTypes.string,
    race: PropTypes.string,
    class: PropTypes.string,
    image_front: PropTypes.string,
    attributes: PropTypes.shape({
      size: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
      weight: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
      endurance: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
      force: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
      intelligence: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
      agility: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
      ecoScore: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    }),
    raceAbilities: PropTypes.oneOfType([
      PropTypes.arrayOf(
        PropTypes.shape({
          name: PropTypes.string.isRequired,
          points: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
        })
      ),
      PropTypes.object, // pour les objets semblables à des tableaux
    ]),
    classActions: PropTypes.shape({
      classActionsAttack: PropTypes.oneOfType([
        PropTypes.arrayOf(
          PropTypes.shape({
            name: PropTypes.string.isRequired,
          })
        ),
        PropTypes.object, // pour les objets semblables à des tableaux
      ]),
      classActionsDefense: PropTypes.oneOfType([
        PropTypes.arrayOf(
          PropTypes.shape({
            name: PropTypes.string.isRequired,
          })
        ),
        PropTypes.object, // pour les objets semblables à des tableaux
      ]),
      classActionsHeal: PropTypes.oneOfType([
        PropTypes.arrayOf(
          PropTypes.shape({
            name: PropTypes.string.isRequired,
          })
        ),
        PropTypes.object, // pour les objets semblables à des tableaux
      ]),
    }),
  }),
  weaponCharacterSelectedForBattle: PropTypes.shape({
    name: PropTypes.string.isRequired,
    image: PropTypes.string.isRequired,
    // Ajoutez d'autres propriétés de l'arme si nécessaire
  }),
  armorCharacterSelectedForBattle: PropTypes.shape({
    name: PropTypes.string.isRequired,
    image: PropTypes.string.isRequired,
    // Ajoutez d'autres propriétés de l'armure si nécessaire
  }),
  account: PropTypes.object,
};

export default CharacterDetailsPlay;
