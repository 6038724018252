// src/services/buyAngelus.js
import { web3, angelusToken, connectWallet } from './blockchainService';
import {  gas_mint_buyAngelus, gasPrice_mint_buyAngelus } from '../config';

export const buyAngelusTokens = async (amountAngelus) => {
  try {
    const account = await connectWallet();
    if (!account) {
      throw new Error('Connexion au wallet échouée.');
    }
 
    const tokensPerMatic = 1000; // 1 MATIC = 1000 Angelus tokens
    const maticAmount = amountAngelus / tokensPerMatic;

    // Vérifier que l'utilisateur a bien des MATIC
    const balance = await web3.eth.getBalance(account);
    if (parseFloat(web3.utils.fromWei(balance, 'ether')) < maticAmount) {
      throw new Error('Solde en MATIC insuffisant pour acheter ces Angelus tokens.');
    }
 
    // Appeler la fonction du contrat pour acheter les tokens
    await angelusToken.methods.buyTokens().send({
        from: account,
        value: web3.utils.toWei(maticAmount.toString(), 'ether'),
        gas: gas_mint_buyAngelus,
        gasPrice: web3.utils.toWei(gasPrice_mint_buyAngelus, 'gwei'),
    });
   
  
    return true;
  } catch (error) {
    console.error('Erreur lors de l\'achat des tokens Angelus:', error);
    throw error;
  }
};
   