import React, { useEffect, useState, useCallback } from 'react';
import axios from 'axios';


import { loadBlockchainAndBackendData } from '../services/loadBlockchainAndBackendDataService';


import DiceComponent from './DiceComponent';
import {  
    ecoScoreImpactFactor, 
    url_backend,
    gas_mint_armure,
    gasPrice_mint_armure,
    gas_mint_arme,
    gasPrice_mint_arme, 
    gas_token_reward,
    gasPrice_token_reward, 
} from '../config';

// **Importer les fonctions et contrats nécessaires**
import {
    web3,
    angelusNFTArme,
    angelusNFTArmure,
    connectWallet,
    angelusToken
  } from '../services/blockchainService';

const calculateActionOutcome = (action, diceResult, character, weapon, armor, ecoScore, infosCharacterForPlay) => {
    let outcome = { damage: 0, heal: 0, defense: 0 };

    // Modificateurs des attributs
    const attributeModifier = Number(character.attributes.force || 0);
    const weaponModifier = Number(weapon ? weapon.attack : 0);
    const armorModifier = Number(armor ? armor.defense : 0);

    // Récupérer l'eco-score du personnage
    if(!ecoScore){
        ecoScore = Number(infosCharacterForPlay.attributes.ecoScore || 0); // Assurez-vous que c'est un nombre
    }
    
    
    
    // Calculer le modificateur basé sur l'eco-score
    
    let ecoScoreModifier = 0;
    if (ecoScore <= 0) {
        ecoScoreModifier = ecoScore; // Malus (négatif ou zéro)
    } else {
        ecoScoreModifier = ecoScore; // Bonus (positif)
    }
    ecoScoreModifier = ecoScore * ecoScoreImpactFactor;
   
    // Total des modificateurs
    const totalModifier = attributeModifier + weaponModifier + armorModifier + ecoScoreModifier;

    const points = Number(action.points);
    // Calcul basé sur le type d'action
    
    if (action.type === 'attack') {
        outcome.damage = (points + totalModifier) * (diceResult / 6) * 2;
    } else if (action.type === 'heal') {
        outcome.heal = (points + totalModifier) * (diceResult / 6) * 1.5;
    } else if (action.type === 'defense') {
        outcome.defense = (points + totalModifier) * (diceResult / 6) * 1;
    }

    return outcome;
};



const Combat = ({ playerId, dispatch, currentCombat, combatState: initialCombatState, combatLog: initialCombatLog, infosCharacterForPlay, weaponCharacterSelectedForBattle, armorCharacterSelectedForBattle, onCombatEnd  }) => {
    const [selectedAction, setSelectedAction] = useState(null);
    const [isCombatOver, setIsCombatOver] = useState(false);
    const [combatLog, setCombatLog] = useState(initialCombatLog || []); 
    const [enemyDetails, setEnemyDetails] = useState(currentCombat?.enemy || null);
    const [playerHealth, setPlayerHealth] = useState(Number(infosCharacterForPlay.attributes.health));
    const [enemyHealth, setEnemyHealth] = useState(Number(currentCombat.enemy?.health));

    // Au début du composant Combat.js
    const [currentTurn, setCurrentTurn] = useState(null); // 'player' ou 'enemy'
    const [diceRollNeeded, setDiceRollNeeded] = useState(true); // On a besoin du dé au début
    const [diceRollForAction, setDiceRollForAction] = useState(false);

    const [combatState, setCombatState] = useState(initialCombatState || []); 
    const ecoScore = infosCharacterForPlay.attributes.ecoScore || 0; // Valeur par défaut de 0 si non défini
    
    const [isCombatStarted, setIsCombatStarted] = useState(false);

    const [canPlay, setCanPlay] = useState(false);

    const [timeRemaining, setTimeRemaining] = useState(null);

    const [reward, setReward] = useState(null); 


    useEffect(() => {
        if (isCombatOver) {
          updateAfterCombat();
        }
      }, [isCombatOver]);
     
      const updateAfterCombat = async () => {
        dispatch({ type: 'SET_IS_LOADING', payload: true });
        try {
            const combatResult = combatState; // 'victory' ou 'defeat'
            const characterId = infosCharacterForPlay.character_id;

            const account = await connectWallet();
            if (!account) {
            throw new Error('Connexion au wallet échouée.');
            }

      //  console.log("infosCharacterForPlay.attributes.next_combat_time" , infosCharacterForPlay.attributes.next_combat_time)
            const response = await axios.post(`${url_backend}/story/update-after-combat`, {
                player_id: playerId,
                character_id: characterId,
                combat_result: combatResult,
                player_wallet_address: account,
            });
    
          if (combatResult === 'victory' && response.data.reward) {
            // **Stocker la récompense pour traitement**
            setReward(response.data.reward);
          } else {
            // Après avoir mis à jour le backend, appeler onCombatEnd
            if (onCombatEnd) {
              onCombatEnd(combatResult);
            }
          }

          // Après avoir mis à jour le backend, appeler onCombatEnd
        /*  if (onCombatEnd) {
            onCombatEnd(combatResult);
          }*/
            dispatch({ type: 'SET_IS_LOADING', payload: false });
        } catch (error) {
          console.error('Erreur lors de la mise à jour après le combat:', error);
          dispatch({ type: 'SET_IS_LOADING', payload: false });
        }
      };
/*
    const updateCombatResult = async () => {
        try {
            const result = combatState; // 'victory' ou 'defeat'
            const characterId = infosCharacterForPlay.character_id;
            const combatId = currentCombat.combat_id;

            

            await axios.post(`${url_backend}/combat/update-combat-result`, {
            character_id: characterId,
            combat_id: combatId,
            result: result,
            });

            // Appeler onCombatEnd après la mise à jour
            if (onCombatEnd) {
            onCombatEnd(result);
            }
        } catch (error) {
            console.error('Erreur lors de la mise à jour du résultat du combat:', error);
        }
    };*/
    
    // **Fonction pour gérer la récupération de la récompense**
  const handleCollectReward = async () => {
    if (!reward) return;
    dispatch({ type: 'SET_IS_LOADING', payload: true });
    console.log("reward", reward)

    if (reward.type === 'tokens') {
      // **Gestion de la récompense en tokens**
      alert(`Vous avez reçu ${reward.amount} Angelus tokens !`);
      try {
        dispatch({ type: 'SET_IS_LOADING', payload: true });

        const account = await connectWallet();
        if (!account) {
          throw new Error('Connexion au wallet échouée.');
        }

        // Appeler la fonction claimReward du contrat
        await angelusToken.methods.claimReward().send({
          from: account,
          gas: gas_token_reward, // Assurez-vous de définir ces variables dans votre config
          gasPrice: web3.utils.toWei(gasPrice_token_reward, 'gwei'),
          
        });

        alert(`Vous avez reçu ${reward.amount} Angelus tokens !`);
      } catch (error) {
        console.error('Erreur lors de la réclamation des tokens:', error);
        alert('Erreur lors de la réclamation des tokens. Veuillez réessayer.');
      } finally {
        
        const  connectedAccount = await connectWallet();
        
     
        setTimeout(async () => {
            await loadBlockchainAndBackendData(connectedAccount, dispatch);
            dispatch({ type: 'SET_IS_LOADING', payload: false });
          }, 4000); // Délai de 1 seconde
        
       
      }

      // Optionnellement, rafraîchir le solde de tokens du joueur si nécessaire
    } 
    else if (reward.type === 'item') {
        console.log("reward.type", reward.type)
        console.log("reward.item_data", reward.item_data)
      // **Gestion de la récompense en item**
      await mintRewardItem(reward.item_data);
      alert('Vous avez reçu un nouvel item !');
      const  connectedAccount = await connectWallet();
        
     
        setTimeout(async () => {
            await loadBlockchainAndBackendData(connectedAccount, dispatch);
            dispatch({ type: 'SET_IS_LOADING', payload: false });
          }, 4000); // Délai de 1 seconde
    }

    // Après avoir traité la récompense, appeler onCombatEnd
    if (onCombatEnd) {
      onCombatEnd('victory');
    }
  };

  // **Fonction pour mint l'item récompense**


  const mintRewardItem = async (itemData) => {
    dispatch({ type: 'SET_IS_LOADING', payload: true });

    if (!web3) {
      console.error('web3 non défini. Assurez-vous que MetaMask est installé.');
      dispatch({ type: 'SET_IS_LOADING', payload: false });
      return;
    }

    let nftContract;
   // let gasLimit;
   // let gasPrice;

    console.log("*****************************************")
    console.log("itemData" , itemData)
    console.log("*****************************************")
    if (itemData.typeItem === 'armor') {
      if (!angelusNFTArmure) {
        console.error("Le contrat angelusNFTArmure n'a pas pu être chargé !");
        dispatch({ type: 'SET_IS_LOADING', payload: false });
        return;
      }
      nftContract = angelusNFTArmure;
    //  gasLimit = gas_mint_armure;
    //  gasPrice = web3.utils.toWei(gasPrice_mint_armure, 'gwei') ;
      
    } else if (itemData.typeItem === 'weapon') {
      if (!angelusNFTArme) {
        console.error("Le contrat angelusNFTArme n'a pas pu être chargé !");
        dispatch({ type: 'SET_IS_LOADING', payload: false });
        return;
      }
      nftContract = angelusNFTArme;
      //gasLimit = gas_mint_arme;
     
      
     // gasPrice = web3.utils.toWei(gasPrice_mint_arme, 'gwei') ;
    } else {
      console.error("Type d'item inconnu :", itemData.typeItem);
      dispatch({ type: 'SET_IS_LOADING', payload: false });
      return;
    }

    let account = await connectWallet();
    

    if (account) {
      dispatch({ type: 'SET_ACCOUNT', payload: account });
    } else {
      console.error("Le wallet n'a pas pu être chargé !");
    }

    

    try {
      // Mint l'item
      const imageUrl = itemData.image;
    console.log("imageUrl", imageUrl)
      if (itemData.typeItem === 'armor') {
        // **Mint armure**
        console.log("armor itemData" , itemData)
        
        const receipt = await nftContract.methods
          .mint(
            itemData.name,
            itemData.force,
            itemData.endurance,
            itemData.agility,
            itemData.intelligence,
            itemData.eco_score,
            itemData.type,
            imageUrl,
            itemData.rarity,
            itemData.defense
          )
          .send({
            from: account,
            gas: gas_mint_armure,
            gasPrice: web3.utils.toWei(gasPrice_mint_armure, 'gwei'),
          });

        const mintedEvent = receipt.events.Minted;
        const tokenId = mintedEvent.returnValues.tokenId.toString();

        itemData.token_id = tokenId;
      } else if (itemData.typeItem === 'weapon') {
        // **Mint arme**
        console.log("Weapon itemData" , itemData)
        const receipt = await nftContract.methods
          .mint(
            itemData.name,
            itemData.force,
            itemData.endurance,
            itemData.agility,
            itemData.intelligence,
            itemData.eco_score,
            itemData.type,
            imageUrl,
            itemData.rarity,
            itemData.attack
          )
          .send({
            from: account,
            gas: gas_mint_arme,
            gasPrice: web3.utils.toWei(gasPrice_mint_arme, 'gwei'),
      
          });

        const mintedEvent = receipt.events.Minted;
        const tokenId = mintedEvent.returnValues.tokenId.toString();

        itemData.token_id = tokenId;
      }

      // **Sauvegarder l'item dans la base de données**
      await saveItemToDBReward(itemData);
    } catch (error) {
      console.error('Erreur lors du minting:', error);
    } finally {
      dispatch({ type: 'SET_IS_LOADING', payload: false });
    }
  };

  // **Fonction pour sauvegarder l'item dans la base de données**
  const saveItemToDBReward = async (itemData) => {
    const sanitizedData = {
      token_id: itemData.token_id,
      name: itemData.name,
      type: itemData.type,
      attack_value: itemData.attack || 0,
      defense_value: itemData.defense || 0,
      rarity: itemData.rarity,
      image: itemData.image,
      player_id: playerId,
      typeItem: itemData.typeItem
    };

    try {
      await axios.post(`${url_backend}/inventory/save-item-to-dbreward`, sanitizedData);
      console.log('Item enregistré dans la base de données.');
    } catch (error) {
      console.error('Erreur lors de l\'enregistrement de l\'item:', error);
    }
  };


    useEffect(() => {
    if (infosCharacterForPlay && infosCharacterForPlay.attributes && infosCharacterForPlay.attributes.next_combat_time) {
        const interval = setInterval(() => {
            const currentTime = new Date();
            const nextCombatTimeStr = infosCharacterForPlay.attributes.next_combat_time;
            const nextCombatTime = new Date(nextCombatTimeStr);

            const diff = nextCombatTime - currentTime;

          //  console.log("diff", diff)
            if (diff <= 0) {
                setCanPlay(true);
                setTimeRemaining(null);
                clearInterval(interval);
            } else {
                setCanPlay(false);
                setTimeRemaining(diff);
            }
        }, 1000);

        return () => clearInterval(interval);
    }
}, [infosCharacterForPlay]);


   // const [playerHealthInitial, setPlayerHealthInitial] = useState(currentCombat.enemy?.health);
   // const [enemyHealthInitial, setEnemyHealthInitial] = useState(currentCombat.enemy?.health);

    //console.log("currentCombat : ", currentCombat)
   // console.log("combatState : ", combatState)
    //console.log("weaponCharacterSelectedForBattle : ", weaponCharacterSelectedForBattle)
    //console.log("infosCharacterForPlay : ", infosCharacterForPlay)
    //console.log("armorCharacterSelectedForBattle : ", armorCharacterSelectedForBattle)
    
//console.log("enemyDetails",enemyDetails)

//console.log("infosCharacterForPlay",infosCharacterForPlay)
   
//console.log("currentCombat", currentCombat)

useEffect(() => {
    //console.log("Initialisation du combat, currentCombat:", currentCombat);
    if (currentCombat?.enemy) {
      const enemy = currentCombat.enemy;
      const enemyActions = generateEnemyActions(enemy);
      setEnemyDetails({ ...enemy, actions: enemyActions });
  
      // Initialiser les points de vie uniquement si le combat n'a pas encore commencé
      if (!isCombatStarted) {
        setEnemyHealth(enemy.health);
        setPlayerHealth(infosCharacterForPlay.attributes.health);
        setIsCombatStarted(true);
      }
    }
  }, [currentCombat, infosCharacterForPlay]);

    useEffect(() => {
        if (combatState === 'victory' || combatState === 'defeat') {
            setIsCombatOver(true);
        }
    }, [combatState]);


    const handleActionSelection = (action) => {
        if (isCombatOver) return;
        if (currentTurn !== 'player' || diceRollForAction) return;

        setSelectedAction(action);
        setDiceRollForAction(true); // On attend le résultat du dé pour l'action
      };

/*
    const performAction = async () => {
        if (!selectedAction) return;

        try {
            dispatch({ type: 'SET_IS_LOADING', payload: true });

            const response = await axios.post(`${url_backend}/combat/action`, {
                player_id: playerId,
                combat_id: currentCombat.combat_id,
                action: selectedAction,
            });

            dispatch({ type: 'ADD_TO_COMBAT_LOG', payload: response.data.combatLogEntry });
            dispatch({ type: 'UPDATE_COMBAT_STATE', payload: response.data.combatState });

            setCombatLog(prevLog => [...prevLog, response.data.combatLogEntry]);
            setSelectedAction(null);
            dispatch({ type: 'SET_IS_LOADING', payload: false });

            setPlayerHealth(response.data.playerHealth);
            setEnemyHealth(response.data.enemyHealth);

            if (response.data.combatState === 'victory' || response.data.combatState === 'defeat') {
                setIsCombatOver(true);
            }

        } catch (error) {
            console.error('Erreur lors de l\'action de combat:', error);
            dispatch({ type: 'SET_IS_LOADING', payload: false });
        }
    };
*/
    const initiateCombat = useCallback(async () => {
        try {
            const response = await axios.post(`${url_backend}/combat/start`, {
                player_id: playerId,
                character_id: infosCharacterForPlay.character_id,
            });

            dispatch({ type: 'SET_CURRENT_COMBAT', payload: response.data.combat });
            dispatch({ type: 'UPDATE_COMBAT_STATE', payload: 'ongoing' });

        } catch (error) {
            console.error('Erreur lors de l\'initiation du combat:', error);
        }
    }, [playerId, dispatch, infosCharacterForPlay.character_id]);

    useEffect(() => {
    if (!currentCombat && playerId && canPlay) {
        initiateCombat();
    }
}, [currentCombat, playerId, canPlay, initiateCombat]);

    

const renderActionsAttacks = () => {
        const actions = [];

    if (infosCharacterForPlay.classActions && infosCharacterForPlay.classActions.classActionsAttack) {
        const attackActions = [
            infosCharacterForPlay.classActions.classActionsAttack.classActionsAttackFirst,
            infosCharacterForPlay.classActions.classActionsAttack.classActionsAttackSecond,
            infosCharacterForPlay.classActions.classActionsAttack.classActionsAttackThird,
        ];

        attackActions.forEach((action, index) => {
            if (action) {
                // Ajouter le type 'attack' à l'action
                const actionWithType = { ...action, type: 'attack' };

                actions.push(
                    <button
                        key={`attack-${index}`}
                        onClick={() => handleActionSelection(actionWithType)}
                        className={`action-button ${selectedAction === action.name ? 'selected' : ''}`}
                        disabled={currentTurn !== 'player' || diceRollForAction || isCombatOver}
                    >
                        {action.name} ({action.points.toString()} pts)
                    </button>
                );
            }
        });
    }

     
        return actions;
};



const renderActionsDefenses = () => {
    const actions = [];

    if (infosCharacterForPlay.classActions && infosCharacterForPlay.classActions.classActionsDefense) {
        const defenseActions = [
            infosCharacterForPlay.classActions.classActionsDefense.classActionsDefenseFirst,
            infosCharacterForPlay.classActions.classActionsDefense.classActionsDefenseSecond,
            infosCharacterForPlay.classActions.classActionsDefense.classActionsDefenseThird,
        ];

        defenseActions.forEach((action, index) => {
            if (action) {
                // Ajouter le type 'defense' à l'action
                const actionWithType = { ...action, type: 'defense' };

                actions.push(
                    <button
                        key={`attack-${index}`}
                        onClick={() => handleActionSelection(actionWithType)}
                        className={`action-button ${selectedAction === action.name ? 'selected' : ''}`}
                        disabled={currentTurn !== 'player' || diceRollForAction || isCombatOver}
                    >
                        {action.name} ({action.points.toString()} pts)
                    </button>
                );
            }
        });
    }

 
    return actions;
};



const renderActionsHeals = () => {
    const actions = [];

    if (infosCharacterForPlay.classActions && infosCharacterForPlay.classActions.classActionsHeal) {
        const healActions = [
            infosCharacterForPlay.classActions.classActionsHeal.classActionsHealFirst,
            infosCharacterForPlay.classActions.classActionsHeal.classActionsHealSecond,
            infosCharacterForPlay.classActions.classActionsHeal.classActionsHealThird,
        ];

        healActions.forEach((action, index) => {
            if (action) {
                 // Ajouter le type 'heal' à l'action
                const actionWithType = { ...action, type: 'heal' };

                actions.push(
                    <button
                        key={`attack-${index}`}
                        onClick={() => handleActionSelection(actionWithType)}
                        className={`action-button ${selectedAction === action.name ? 'selected' : ''}`}
                        disabled={currentTurn !== 'player' || diceRollForAction || isCombatOver}
                    >
                        {action.name} ({action.points.toString()} pts)
                    </button>
                );
            }
        });
    }

 
    return actions;
};







const handleInitiativeDiceRoll = (result) => {
    if (result >= 3) {
      alert('Vous commencez le combat !');
      setCurrentTurn('player');
    } else {
      alert('L\'ennemi commence le combat !');
      setCurrentTurn('enemy');
      enemyAction(); // L'ennemi effectue son action
    }
    setDiceRollNeeded(false); // On n'a plus besoin du dé pour l'initiative
  };

  const handleActionDiceRoll = (diceResult) => {
    if (!selectedAction) return;

    // Calculer l'effet de l'action en fonction du résultat du dé
    const actionOutcome = calculateActionOutcome(
        selectedAction,
        diceResult,
        infosCharacterForPlay,
        weaponCharacterSelectedForBattle,
        armorCharacterSelectedForBattle,
        ecoScore, 
        infosCharacterForPlay
    );

    //console.log("action  = ", selectedAction.type)
    //console.log("actionOutcome  = ", actionOutcome)
    // Appliquer l'effet de l'action
    // Appliquer l'effet de l'action
    if (selectedAction.type === 'attack') {
        setEnemyHealth(prevHealth => {
            const newHealth = Number(Math.max(prevHealth - actionOutcome.damage, 0));
            checkCombatEnd(playerHealth, newHealth); // Passez les nouvelles valeurs
            return newHealth;
          });
        setCombatLog(prevLog => [...prevLog, `Vous avez infligé ${actionOutcome.damage.toFixed(2)} points de dégâts à l'ennemi.`]);
    } else if (selectedAction.type === 'heal') {
        setPlayerHealth(prevHealth => Number(Math.min(prevHealth + actionOutcome.heal, Number(infosCharacterForPlay.attributes.health))));
        setCombatLog(prevLog => [...prevLog, `Vous vous êtes soigné de ${actionOutcome.heal.toFixed(2)} points de vie.`]);
    } else if (selectedAction.type === 'defense') {
        // Gérer la défense si nécessaire
        setCombatLog(prevLog => [...prevLog, `Vous vous préparez à vous défendre.`]);
    }



    setDiceRollForAction(false);
    checkCombatEnd();

    setTimeout(() => {
        // Choisir une action aléatoire
        // ... [le reste de votre code]
    
        // Après l'action de l'ennemi, passer le tour au joueur
        if (!isCombatOver) {
            setCurrentTurn('enemy');
            enemyAction();
        }
      }, 1000); // Délai de 1 seconde
   
};

// Fonction pour générer les actions de l'ennemi avec des points
const generateEnemyActions = (enemy) => {
    const actions = [];
  
    // Actions d'attaque
    if (enemy.attack_action_1) {
      actions.push({
        name: enemy.attack_action_1,
        type: 'attack',
        points: enemy.strength || 10, // Utiliser la force de l'ennemi ou une valeur par défaut
      });
    }
  
    if (enemy.attack_action_2) {
      actions.push({
        name: enemy.attack_action_2,
        type: 'attack',
        points: enemy.strength || 10,
      });
    }
  
    // Actions de défense
    if (enemy.defense_action_1) {
      actions.push({
        name: enemy.defense_action_1,
        type: 'defense',
        points: enemy.defense || 5, // Utiliser la défense de l'ennemi ou une valeur par défaut
      });
    }
  
    if (enemy.defense_action_2) {
      actions.push({
        name: enemy.defense_action_2,
        type: 'defense',
        points: enemy.defense || 5,
      });
    }
  
    // Actions de soin
    if (enemy.heal_action_1) {
      actions.push({
        name: enemy.heal_action_1,
        type: 'heal',
        points: enemy.intelligence || 5, // Utiliser l'intelligence de l'ennemi ou une valeur par défaut
      });
    }
  
    if (enemy.heal_action_2) {
      actions.push({
        name: enemy.heal_action_2,
        type: 'heal',
        points: enemy.intelligence || 5,
      });
    }
  
    return actions;
  };

  const enemyAction = () => {
    if (isCombatOver) return;
  
    setCurrentTurn('enemy');
  
    setTimeout(() => {
      const enemyActions = enemyDetails.actions || [];
  
      if (enemyActions.length === 0) return;
  
      const randomIndex = Math.floor(Math.random() * enemyActions.length);
      const action = enemyActions[randomIndex];
  
      // Lancer le dé pour l'action de l'ennemi
      const diceResult = Math.ceil(Math.random() * 6);
  
      // Calculer l'effet de l'action
      const actionOutcome = calculateEnemyActionOutcome(action, diceResult, enemyDetails);
  
      // Appliquer l'effet de l'action
      if (action.type === 'attack') {
        setPlayerHealth(prevHealth => {
            const newHealth = Math.max(prevHealth - actionOutcome.damage, 0);
            checkCombatEnd(newHealth, enemyHealth); // Passez les nouvelles valeurs
            return newHealth;
          });
        setCombatLog(prevLog => [...prevLog, `L'ennemi utilise ${action.name} et vous inflige ${actionOutcome.damage.toFixed(2)} points de dégâts.`]);
      } else if (action.type === 'heal') {
        setEnemyHealth(prevHealth => Math.min(prevHealth + actionOutcome.heal, enemyDetails.health));
        setCombatLog(prevLog => [...prevLog, `L'ennemi utilise ${action.name} et se soigne de ${actionOutcome.heal.toFixed(2)} points de vie.`]);
      } else if (action.type === 'defense') {
        setCombatLog(prevLog => [...prevLog, `L'ennemi utilise ${action.name} et se prépare à se défendre.`]);
      }
  
      checkCombatEnd();
  
      if (!isCombatOver) {
        setCurrentTurn('player');
      }
  
    }, 1000); // Délai de 1 seconde
  };
  



  const calculateEnemyActionOutcome = (action, diceResult, enemy) => {
    let outcome = { damage: 0, heal: 0, defense: 0 };
  
    // Modificateur d'attribut
    const attributeModifier = Number(enemy.strength || 0);
    const points = Number(action.points || 0);
  
    // Calcul basé sur le type d'action
    if (action.type === 'attack') {
      outcome.damage = (points + attributeModifier) * (diceResult / 6) * 2;
    } else if (action.type === 'heal') {
      outcome.heal = (points + Number(enemy.intelligence || 0)) * (diceResult / 6) * 1.5;
    } else if (action.type === 'defense') {
      outcome.defense = (points + Number(enemy.defense || 0)) * (diceResult / 6) * 1;
    }
  
    return outcome;
  };
  
  
  const checkCombatEnd = (newPlayerHealth, newEnemyHealth) => {
    if (newPlayerHealth <= 0) {
      setIsCombatOver(true);
      setCombatState('defeat');
      setCombatLog(prevLog => [...prevLog, 'Vous avez été vaincu.']);
    } else if (newEnemyHealth <= 0) {
      setIsCombatOver(true);
      setCombatState('victory');
      setCombatLog(prevLog => [...prevLog, 'Vous avez remporté le combat !']);
    }
  };
  
  
    // Handle cases where the player cannot play
    if (!canPlay) {
        const hours = Math.floor((timeRemaining / (1000 * 60 * 60)) % 24);
        const minutes = Math.floor((timeRemaining / (1000 * 60)) % 60);
        const seconds = Math.floor((timeRemaining / 1000) % 60);
    
        return (
            <div className="combat-wait">
                <h3>Votre personnage doit se reposer avant de pouvoir combattre à nouveau.</h3>
                <p>Temps restant : {hours}h {minutes}m {seconds}s</p>
            </div>
        );
    }

    if (!currentCombat) {
        return <div>Initiation du combat...</div>;
    } 

    return (
        <div className="combat-container">
            <div className='header-combat'>
                <div className="health-bar">
            
                    <div
                
                        className="health-bar-fill"
                        style={{
                            width: `${(Number(playerHealth) / Number(infosCharacterForPlay.attributes.health)) * 100}%`,
                            backgroundColor:
                                playerHealth > Number(infosCharacterForPlay.attributes.health) * 0.6
                                    ? 'green'
                                    : playerHealth > Number(infosCharacterForPlay.attributes.health) * 0.2
                                    ? 'orange'
                                    : 'red',
                        }}
                    >
                        {playerHealth} HP
                    </div>
                </div>
                <div className="health-bar">
                
                    <div
                        className="health-bar-fill"
                        style={{
                            width: `${(Number(enemyHealth) / Number(currentCombat.enemy?.health)) * 100}%`,
                            backgroundColor:
                                enemyHealth > Number(currentCombat.enemy?.health) * 0.6
                                    ? 'green'
                                    : enemyHealth > Number(currentCombat.enemy?.health) * 0.2
                                    ? 'orange'
                                    : 'red',
                        }}
                    >
                        {enemyHealth} HP
                    </div>
                </div>
            </div>
            <h2 className='h2-Combat'>Combat contre {currentCombat.enemy?.name || 'Ennemi inconnu'}</h2>
            <div className="combat-log">
                {combatLog.map((log, index) => (
                    <p key={index}>{log}</p>
                ))}
            </div>
            {!isCombatOver && !diceRollNeeded &&(
                <h3 className="turn-indicator">
                    {currentTurn === 'player' ? 'C\'est votre tour de jouer' : 'L\'ennemi est en train de jouer...'}
                </h3>
                )}
            {diceRollNeeded && canPlay && (
                <div className="dice-section">
                    <h3>Lancez le dé pour déterminer qui commence</h3>
                    <DiceComponent onRollComplete={handleInitiativeDiceRoll} />
                </div>
            )}
            {diceRollForAction && (
                <div className="dice-section">
                    <h3>Lancez le dé pour votre action</h3>
                    <DiceComponent onRollComplete={handleActionDiceRoll} />
                </div>
            )}
            {isCombatOver ? (
                <div className="combat-result">
          {combatState === 'victory' ? (
            <div>
              <h3>Vous avez remporté le combat !</h3>
              {reward ? (
                <div>
                  <button onClick={handleCollectReward}>Récupérer le butin</button>
                </div>
              ) : (
                <p>Chargement de la récompense...</p>
              )}
            </div>
          ) : (
            <h3>Vous avez été défait.</h3>
          )}
        </div>
                ) : (
                <div className="combat-actions">
                   
                    <div className="actions-columns">
                        {renderActionsAttacks()}
                    </div>
                    <div className="actions-columns">
                        {renderActionsDefenses()}
                    </div>
                    <div className="actions-columns">
                        {renderActionsHeals()}
                    </div>
                   
                </div>
            )}
        </div>
    );
};

export default Combat;
