import React from 'react';
import ReactDOM from 'react-dom/client'; // Utiliser React 18 pour le rendu
//import './index.css'; // Import des styles globaux

import './full.css';
import App from './App'; // Import du composant App
import reportWebVitals from './reportWebVitals'; // Optionnel pour mesurer les performances
import Web3 from 'web3'; // Import de Web3.js pour interagir avec MetaMask et la blockchain

const root = ReactDOM.createRoot(document.getElementById('root')); // Définir la racine de l'application React

// Fonction pour charger Web3 et détecter MetaMask
const loadWeb3 = async () => {
  if (window.ethereum) {
    // Si MetaMask est disponible
    window.web3 = new Web3(window.ethereum);
    try {
      // Demander à MetaMask d'autoriser l'application
      await window.ethereum.request({ method: 'eth_requestAccounts' });
      console.log('MetaMask detected and connected.');
    } catch (error) {
      console.error('User denied account access');
    }
  } else if (window.web3) {
    // Si une ancienne version de Web3 est déjà injectée par un autre fournisseur
    window.web3 = new Web3(window.web3.currentProvider);
    console.log('Legacy web3 provider detected.');
  } else {
    // Si MetaMask n'est pas détecté
    window.alert('Non-Ethereum browser detected. Please install MetaMask!');
  }
};
  
// Appeler loadWeb3 pour initialiser la connexion au portefeuille avant le rendu de l'application
loadWeb3(); // Charger Web3 avant de rendre l'application
 
root.render(
  <React.StrictMode>
    <App /> {/* Rendre le composant principal App */}
  </React.StrictMode>
);

// Optionnel : Mesurer les performances de l'application
reportWebVitals();
