import React, { useState, useEffect } from 'react';
import { web3, angelusToken, connectWallet, getPlayerIdFromBackend } from '../services/blockchainService';
import axios from 'axios';
import { url_backend , gasPrice_stacking, gas_stacking} from '../config';

const StakingModal = ({ onClose, playerId }) => {
    const [stakeAmount, setStakeAmount] = useState('');
    const [stakingType, setStakingType] = useState('Flexible');
    const [stakedBalance, setStakedBalance] = useState('0');
    const [rewards, setRewards] = useState('0');
    const [account, setAccount] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [totalBalance, setTotalBalance] = useState('0');
    //const [stakingInfo, setStakingInfo] = useState(null);
  
    const [stakes, setStakes] = useState([]);

    useEffect(() => {
      const init = async () => {
        const acc = await connectWallet();
        setAccount(acc);
        if (acc) {
          await fetchTotalBalance(acc);
          await fetchStakingInfo();
        }

        if(!playerId){
            playerId = await getPlayerIdFromBackend(acc);
        }
      };
      init();
    }, []);
  
    const fetchTotalBalance = async (acc) => {
      try {
        const balance = await angelusToken.methods.balanceOf(acc).call();
        const balanceInEther = web3.utils.fromWei(balance, 'ether');
        setTotalBalance(balanceInEther);
      } catch (error) {
        console.error('Erreur lors de la récupération du solde:', error);
      }
    };
  
    const fetchStakingInfo = async () => {
        try {
            const response = await axios.get(`${url_backend}/staking/${playerId}`);
            if (response.data.staking && response.data.staking.length > 0) {
            setStakes(response.data.staking);

            let totalStaked = 0;
            let totalRewards = 0;

            for (let i = 0; i < response.data.staking.length; i++) {
                const stake = response.data.staking[i];
                console.log(stake)
                const stakeIndex = i; // Assurez-vous que l'index correspond à l'ordre des stakes dans le smart contract

                const stakeInfo = await angelusToken.methods.stakes(account, stakeIndex).call();
                const stakedAmount = web3.utils.fromWei(stakeInfo.amount, 'ether');
                totalStaked += parseFloat(stakedAmount);

                const rewards = await angelusToken.methods.calculateRewards(account, stakeIndex).call();
                const rewardsInEther = web3.utils.fromWei(rewards, 'ether');
                totalRewards += parseFloat(rewardsInEther);
            }

            setStakedBalance(totalStaked.toString());
            setRewards(totalRewards.toString());
            } else {
            setStakes([]);
            setStakedBalance('0');
            setRewards('0');
            }
        } catch (error) {
            console.error('Erreur lors de la récupération des informations de staking:', error);
        }
    };
  
    const handleStake = async () => {
      if (!stakeAmount || parseFloat(stakeAmount) <= 0) {
        alert('Veuillez entrer un montant valide.');
        return;
      }
      if (parseFloat(stakeAmount) > parseFloat(totalBalance)) {
        alert('Vous n\'avez pas assez de tokens pour staker ce montant.');
        return;
      }
      setIsLoading(true);
      try {
        // Envoi de la transaction à la blockchain
        let stakingTypeEnum = 0; // Flexible par défaut
        if (stakingType === 'Fixed3Months') {
          stakingTypeEnum = 1;
        } else if (stakingType === 'Fixed6Months') {
          stakingTypeEnum = 2;
        }
  
        const amountInWei = web3.utils.toWei(stakeAmount, 'ether');
  
       
        await angelusToken.methods.stakeTokens(amountInWei, stakingTypeEnum).send({
          from: account,
          gas: gas_stacking,
          gasPrice: web3.utils.toWei(gasPrice_stacking, 'gwei')
        });
  
        // Enregistrement dans le backend
        await axios.post(`${url_backend}/staking/stake`, {
          player_id: playerId,
          amount: stakeAmount,
          staking_type: stakingType,
        });
  
        alert('Staking réussi !');
        setStakeAmount('');
        await fetchTotalBalance(account);
        await fetchStakingInfo();
      } catch (error) {
        console.error('Erreur lors du staking:', error);
        alert('Une erreur est survenue lors du staking.');
      } finally {
        setIsLoading(false);
      }
    };
  
    const handleWithdraw = async (stakingId, stakeIndex) => {
        setIsLoading(true);
        try {
          // Envoi de la transaction à la blockchain
          await angelusToken.methods.withdrawStake(stakeIndex).send({
            from: account,
            gas: gas_stacking,
            gasPrice: web3.utils.toWei(gasPrice_stacking, 'gwei')
          });
      
          // Mise à jour dans le backend
          await axios.post(`${url_backend}/staking/withdraw`, {
            player_id: playerId,
            staking_id: stakingId,
          });
      
          alert('Retrait réussi !');
          await fetchTotalBalance(account);
          await fetchStakingInfo();
        } catch (error) {
          console.error('Erreur lors du retrait:', error);
          alert('Une erreur est survenue lors du retrait.');
        } finally {
          setIsLoading(false);
        }
      };
      
  
    return (
        <div className="modal-staking">
        <div className="modal-staking-content">
          <span className="close-staking" onClick={onClose}>&times;</span>
          <div className="staking-container">
            <h2>Staking de Tokens Angelus</h2>
            <div>
              <p>Votre solde : {totalBalance} ANG</p>
              <p>Montant staké total : {stakedBalance} ANG</p>
              <p>Récompenses accumulées totales : {rewards} ANG</p>
            </div>
            {/* Toujours afficher le formulaire de staking */}
            <div>
              <h3>Commencer un nouveau staking</h3>
              <div>
                <input
                  type="number"
                  placeholder="Montant à staker"
                  value={stakeAmount}
                  onChange={(e) => setStakeAmount(e.target.value)}
                />
                <select value={stakingType} onChange={(e) => setStakingType(e.target.value)}>
                  <option value="Flexible">Flexible</option>
                  <option value="Fixed3Months">3 Mois (Bonus supplémentaire)</option>
                  <option value="Fixed6Months">6 Mois (Bonus supplémentaire)</option>
                </select>
                <button onClick={handleStake} disabled={isLoading}>
                  {isLoading ? 'En cours...' : 'Staker'}
                </button>
              </div>
            </div>
            {stakes.length > 0 && (
              <div>
                <h3>Vos stakes actifs :</h3>
                {stakes.map((stake, index) => (
                  <div key={stake.staking_id} className="stake-item">
                    <p><strong>Montant staké :</strong> {stake.angelus_staked} ANG</p>
                    <p><strong>Type de staking :</strong> {stake.staking_type}</p>
                    <p><strong>Date de début :</strong> {new Date(stake.start_date).toLocaleString()}</p>
                    {stake.duration > 0 && <p><strong>Date de fin :</strong> {new Date(stake.end_date).toLocaleString()}</p>}
                    <p><strong>Status :</strong> {stake.status}</p>
                    <button onClick={() => handleWithdraw(stake.staking_id, index)} disabled={isLoading}>
                      {isLoading ? 'En cours...' : 'Retirer ce Stake'}
                    </button>
                  </div>
                ))}
              </div>
            )}
          </div>
        </div>
      </div>
    );
  };
  
  export default StakingModal;