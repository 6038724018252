// src/components/Marketplace.js
import React, { useState, useEffect } from 'react';
import {
  angelusMarketplace,
  angelusToken,
  angelusNFT,
  angelusNFTArme,
  angelusNFTArmure,
  connectWallet,
  getNFTs,
  getNFTsArmure,
  getNFTsArme,
  web3,
  fetchEquipment,
} from '../services/blockchainService';
import {
  gas_mint_approuve_market,
  gasPrice_approuve_market,
  gas_mint_listing_market,
  gasPrice_listing_market,
  gas_mint_buy_market,
  gasPrice_buy_market,
} from '../config';

const Marketplace = ({ connectedAccount }) => {
  const [listings, setListings] = useState([]);
  const [filter, setFilter] = useState('all');
  const [bidAmount, setBidAmount] = useState('');
  const [userNFTs, setUserNFTs] = useState([]);
  const [selectedNFT, setSelectedNFT] = useState('');
  const [listingPrice, setListingPrice] = useState('');

  useEffect(() => {
    loadListings();
    loadUserNFTs();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filter]);
  
  const loadListings = async () => {
    let listingsArray = [];
    const totalListings = await angelusMarketplace.methods.nextListingId().call();

    for (let i = 0; i < totalListings; i++) {
      const listing = await angelusMarketplace.methods.listings(i).call();

      if (listing.isSold) continue;

      // Déterminer le contrat NFT
      let nftContract;
      if (
        listing.nftContract.toLowerCase() === angelusNFT.options.address.toLowerCase()
      ) {
        nftContract = angelusNFT;
      } else if (
        listing.nftContract.toLowerCase() ===
        angelusNFTArme.options.address.toLowerCase()
      ) {
        nftContract = angelusNFTArme;
      } else if (
        listing.nftContract.toLowerCase() ===
        angelusNFTArmure.options.address.toLowerCase()
      ) {
        nftContract = angelusNFTArmure;
      } else {
        continue; // Passer les contrats inconnus
      }

      // Appliquer les filtres
      if (filter !== 'all') {
        if (filter === 'character' && nftContract !== angelusNFT) continue;
        if (filter === 'weapon' && nftContract !== angelusNFTArme) continue;
        if (filter === 'armor' && nftContract !== angelusNFTArmure) continue;
      }

      const tokenId = listing.tokenId.toString(); // Convertir en string
      let metadata = {};

      // Récupérer les métadonnées du NFT
      if (nftContract === angelusNFT) {
        metadata = await nftContract.methods.characters(tokenId).call();
      } else if (nftContract === angelusNFTArme) {
        metadata = await nftContract.methods.armes(tokenId).call();
      } else if (nftContract === angelusNFTArmure) {
        metadata = await nftContract.methods.armures(tokenId).call();
      }

      listingsArray.push({
        listingId: i,
        seller: listing.seller,
        nftContract: listing.nftContract,
        tokenId: tokenId,
        price: listing.price.toString(), // Convertir en string
        listingType: listing.listingType.toString(),
        endTime: listing.endTime.toString(),
        highestBid: listing.highestBid.toString(),
        highestBidder: listing.highestBidder,
        metadata: metadata,
      });
    }

    setListings(listingsArray);
  };

  const loadUserNFTs = async () => {
    let account = connectedAccount;
    if (!account) {
      account = await connectWallet();
    }

    // Récupérer les NFTs du personnage
    const { nfts } = await getNFTs(account);
    // Récupérer les NFTs des armures
    const { nftsArmure } = await getNFTsArmure(account);
    // Récupérer les NFTs des armes
    const { nftsArme } = await getNFTsArme(account);

    // Récupérer les items équipés
    const equippedItems = await fetchEquipment(account);

    // Créer des ensembles pour les IDs des items équipés (en tant que strings)
    const equippedCharacterIds = new Set();
    const equippedWeaponIds = new Set();
    const equippedArmorIds = new Set();
 
    // Ajouter les IDs des personnages équipés
    if (equippedItems.selectedCharacters) {
      equippedItems.selectedCharacters.forEach((character) => {
        equippedCharacterIds.add(character.character_id.toString());
      });
    }

    // Ajouter les IDs des armes équipées
    if (equippedItems.selectedWeapons) {
      equippedItems.selectedWeapons.forEach((weapon) => {
        if (weapon.weapon_id) {
          equippedWeaponIds.add(weapon.weapon_id.toString());
        }
      });
    }
 
    // Ajouter les IDs des armures équipées
    if (equippedItems.selectedArmors) {
      equippedItems.selectedArmors.forEach((armor) => {
        if (armor.armor_id) {
          equippedArmorIds.add(armor.armor_id.toString());
        }
      });
    }

    // Filtrer les NFTs pour exclure ceux qui sont équipés
    var filteredNFTs;
    if(nfts && nfts.length > 0){
      filteredNFTs = nfts
      .filter((nft) => !equippedCharacterIds.has(nft.character_id.toString()))
      .map((nft) => ({ ...nft, nftType: 'character', contract: angelusNFT }));
   }else{
    filteredNFTs = [];
   }
   
    
    var filteredNFTsArme;
    if(nftsArme && nftsArme.length > 0){
      filteredNFTsArme = nftsArme
      .filter((nft) => !equippedWeaponIds.has(nft.weapon_id.toString()))
      .map((nft) => ({ ...nft, nftType: 'weapon', contract: angelusNFTArme }));
    }else{
      filteredNFTsArme = [];
     }

    


    var filteredNFTsArmure;
    if(nftsArmure && nftsArmure.length > 0){
      filteredNFTsArmure = nftsArmure
      .filter((nft) => !equippedArmorIds.has(nft.armor_id.toString()))
      .map((nft) => ({ ...nft, nftType: 'armor', contract: angelusNFTArmure }));
    }else{
      filteredNFTsArmure = [];
     }

    // Combiner tous les NFTs non équipés
    const allNFTs = [...filteredNFTs, ...filteredNFTsArmure, ...filteredNFTsArme];

    setUserNFTs(allNFTs);
  };

  const listNFTForSale = async () => {
    try {
      if (selectedNFT === '') {
        alert('Veuillez sélectionner un NFT à vendre.');
        return;
      }

      if (!listingPrice || parseFloat(listingPrice) <= 0) {
        alert('Veuillez entrer un prix valide.');
        return;
      }

      const nftToSell = userNFTs[selectedNFT];
      const nftContract = nftToSell.contract;
      const tokenId = nftToSell.tokenId.toString(); // Convertir en string

      // Approuver le transfert du NFT au marketplace
      await nftContract.methods
        .approve(angelusMarketplace.options.address, tokenId)
        .send({
          from: connectedAccount,
          gas: gas_mint_approuve_market,
          gasPrice: web3.utils.toWei(gasPrice_approuve_market, 'gwei'),
        });

      // Convertir le prix en Wei
      const priceInWei = web3.utils.toWei(listingPrice, 'ether');

      // Appeler la fonction du contrat pour lister le NFT
      await angelusMarketplace.methods
        .createListing(nftContract.options.address, tokenId, priceInWei)
        .send({
          from: connectedAccount,
          gas: gas_mint_listing_market,
          gasPrice: web3.utils.toWei(gasPrice_listing_market, 'gwei'),
        });

      alert('NFT mis en vente avec succès !');

      // Mettre à jour les listings et les NFTs de l'utilisateur
      loadListings();
      loadUserNFTs();
    } catch (error) {
      console.error('Erreur lors de la mise en vente du NFT :', error);
      alert('Erreur lors de la mise en vente du NFT.');
    }
  };

  const buyNFT = async (listingId, price) => {
    try {
      if (!connectedAccount) {
        connectedAccount = await connectWallet();
      }

      // Approuver le transfert des tokens ANG au marketplace
      await angelusToken.methods
        .approve(angelusMarketplace.options.address, price.toString())
        .send({
          from: connectedAccount,
          gas: gas_mint_approuve_market,
          gasPrice: web3.utils.toWei(gasPrice_approuve_market, 'gwei'),
        });

      // Appeler la fonction du contrat pour acheter le NFT
      await angelusMarketplace.methods.buyNFT(listingId).send({
        from: connectedAccount,
        gas: gas_mint_buy_market,
        gasPrice: web3.utils.toWei(gasPrice_buy_market, 'gwei'),
      });

      alert('NFT acheté avec succès !');

      // Mettre à jour les listings et les NFTs de l'utilisateur
      loadListings();
      loadUserNFTs();
    } catch (error) {
      console.error("Erreur lors de l'achat du NFT :", error);
      alert("Erreur lors de l'achat du NFT.");
    }
  };

  const placeBid = async (listingId, bidAmount) => {
    try {
      if (!connectedAccount) {
        connectedAccount = await connectWallet();
      }

      const bidAmountInWei = web3.utils.toWei(bidAmount, 'ether');

      // Approuver le transfert des tokens ANG au marketplace
      await angelusToken.methods
        .approve(angelusMarketplace.options.address, bidAmountInWei)
        .send({ from: connectedAccount });

      // Appeler la fonction du contrat pour placer une enchère
      await angelusMarketplace.methods
        .placeBid(listingId, bidAmountInWei)
        .send({ from: connectedAccount });

      alert('Enchère placée avec succès !');

      // Mettre à jour les listings
      loadListings();
    } catch (error) {
      console.error("Erreur lors du placement de l'enchère :", error);
      alert("Erreur lors du placement de l'enchère.");
    }
  };

  return (
    <div className="marketplace">
      <h2>Marketplace</h2>
      <div className="filters">
        <button onClick={() => setFilter('all')}>Tous</button>
        <button onClick={() => setFilter('character')}>Personnages</button>
        <button onClick={() => setFilter('armor')}>Armures</button>
        <button onClick={() => setFilter('weapon')}>Armes</button>
      </div>

      {/* Section pour mettre en vente un NFT */}
      <div className="list-your-nft">
        <h3>Mettre en vente un NFT</h3>
        <select value={selectedNFT} onChange={(e) => setSelectedNFT(e.target.value)}>
          <option value="">Sélectionnez un NFT</option>
          {userNFTs.map((nft, index) => (
            <option key={index} value={index}>
              {nft.name || `NFT ${nft.tokenId.toString()}`} - {nft.nftType}
            </option>
          ))}
        </select>
        <input
          type="number"
          placeholder="Prix en ANG"
          value={listingPrice}
          onChange={(e) => setListingPrice(e.target.value)}
        />
        <button onClick={listNFTForSale}>Mettre en vente</button>
      </div>

      {/* List e des NFTs en vente */}
      <div className="listings">
        {listings.map((listing) => (
          <div key={listing.listingId} className="listing">
            <h3>{listing.metadata.name || `NFT ${listing.tokenId}`}</h3>
            {/* Afficher l'image du NFT si disponible */}
            {listing.metadata.imageFront && (
              <img src={listing.metadata.imageFront} alt={listing.metadata.name} className='img-market-vente' />
            )}
            {/* Afficher les détails spécifiques en fonction du type de NFT */}
            {listing.listingType === '0' ? (
              <p>Prix fixe : {web3.utils.fromWei(listing.price, 'ether')} ANG</p>
            ) : (
              <div>
                <p>
                  Enchère actuelle :{' '}
                  {web3.utils.fromWei(listing.highestBid, 'ether')} ANG
                </p>
                <p>
                  Fin de l'enchère :{' '}
                  {new Date(Number(listing.endTime) * 1000).toLocaleString()}
                </p>
              </div>
            )}
            {listing.listingType === '0' ? (
              <button onClick={() => buyNFT(listing.listingId, listing.price)}>
                Acheter
              </button>
            ) : (
              <div>
                <input
                  type="number"
                  placeholder="Montant de l'enchère"
                  value={bidAmount}
                  onChange={(e) => setBidAmount(e.target.value)}
                />
                <button onClick={() => placeBid(listing.listingId, bidAmount)}>
                  Enchérir
                </button>
              </div>
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

export default Marketplace;
