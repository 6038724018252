import moment from 'moment';
import { url_backend } from '../config';
import {  connectWallet } from '../services/blockchainService';
import axios from 'axios';
import {  /*fetchEquipment,*/ getNFTs , getNFTsArme, getNFTsArmure } from '../services/blockchainService';


// Fonction pour récupérer les détails d'une armure via son armor_id
export const fetchWeaponDetails = async (weapon_id) => {
  try {
    const response = await axios.get(`${url_backend}/inventory/weapon/${weapon_id}`);
   // console.log("Détails de l'arme récupérés :", response.data);  // Log pour vérifier les données de l'arme
    return response.data;
  } catch (error) {
    console.error('Erreur lors de la récupération des détails de l\'arme:', error);
    return null;  // Retourner null en cas d'erreur
  }
};
 
export const fetchArmorDetails = async (armor_id) => {
  try {
    const response = await axios.get(`${url_backend}/inventory/armor/${armor_id}`);
   // console.log("Détails de l'armure récupérés :", response.data);  // Log pour vérifier les données de l'armure
    return response.data;
  } catch (error) {
    console.error('Erreur lors de la récupération des détails de l\'armure:', error);
    return null;  // Retourner null en cas d'erreur
  }
};
 
export const getCharactereInfoForBattle = async (character_id, account, dispatch) => {
  dispatch({ type: 'SET_IS_LOADING', payload: true });
  try {
    var armorCharacter = null;
    var weaponCharacter = null;
    var characterSelectedInfos = null;

    if (character_id) {
      if (!account) {
        account = await connectWallet();
      }
 
      const listCharactere = await getNFTs(account);
      
      // Boucle sur les personnages pour récupérer celui sélectionné
      for (const character of listCharactere.nfts) {
        if (character.character_id === character_id) {
          characterSelectedInfos = character;

          // Récupération des armes
          if (character.weapon_id) {
            const listArmes = await getNFTsArme(account);
            weaponCharacter = listArmes.nftsArme.find(arme => arme.weapon_id === character.weapon_id);
           // console.log("Arme récupérée :", weaponCharacter);
          }

          // Récupération des armures
          if (character.armor_id) {
            const listArmures = await getNFTsArmure(account);
            armorCharacter = listArmures.nftsArmure.find(armure => armure.armor_id === character.armor_id);
          //  console.log("Armure récupérée :", armorCharacter);
          }

          // Dispatch des données récupérées
          dispatch({ type: 'CHARACTER_FOR_BATTLE_SELECTED', payload: characterSelectedInfos });
          dispatch({ type: 'WEAPON_CHARACTER_FOR_BATTLE_SELECTED', payload: weaponCharacter });
          dispatch({ type: 'ARMOR_CHARACTER_FOR_BATTLE_SELECTED', payload: armorCharacter });

          break; // Sortir de la boucle une fois le personnage trouvé
        }
      }

      dispatch({ type: 'SET_IS_LOADING', payload: false });
      return {
        character: characterSelectedInfos,
        weaponCharacter: weaponCharacter,
        armorCharacter: armorCharacter
      };
    }
  } catch (error) {
    dispatch({ type: 'SET_IS_LOADING', payload: false });
    console.error('Erreur lors de la récupération des détails du personnage:', error);
    return null;
  }
};

  
export const calculateTimeRemainingAndProgress = (nextCombatTime) => {
  //console.log("nextCombatTime", nextCombatTime)
  const now = moment();
  const nextCombat = moment(nextCombatTime);
  const duration = moment.duration(nextCombat.diff(now));

  let timeRemaining = 'Prêt pour le combat';
  let progress = 100;
  
  if (duration.asMilliseconds() > 0) {
    const hours = Math.floor(duration.asHours());
    const minutes = duration.minutes();
    timeRemaining = `${hours} heures et ${minutes} minutes`;

    const totalDuration = 4 * 60 * 60 * 1000; // 4 heures en millisecondes
    const elapsed = totalDuration - duration.asMilliseconds();
    progress = Math.min(100, (elapsed / totalDuration) * 100);
  }

  return { timeRemaining, progress };
};

 
export const validateCharacterData = (data) => {
    //console.log(data)
    
    // 1. Vérifier et convertir les champs de base
    if (!data.name || typeof data.name !== 'string') {
      data.name = String(data.name || '');
    }
    
    if (!data.race || typeof data.race !== 'string') {
      data.race = String(data.race || '');
    }
    
    if (!data.class || typeof data.class !== 'string') {
      data.class = String(data.class || '');
    }
    
    if (!data.gender || typeof data.gender !== 'string') {
      data.gender = String(data.gender || '');
    }
    
    if (!data.imageFront || typeof data.imageFront !== 'string') {
      data.imageFront = String(data.imageFront || '');
    }
    
    if (!data.imageBack || typeof data.imageBack !== 'string') {
      data.imageBack = String(data.imageBack || '');
    }
   
    // 2. Vérifier et convertir les attributs
    if (!data.attributes || typeof data.attributes !== 'object') {
      data.attributes = {
        size: 0,
        weight: 0,
        endurance: 0,
        force: 0,
        agility: 0,
        intelligence: 0,
        ecoScore: 0,
      };
    } else {
      data.attributes.size = Number(data.attributes.size) || 0;
      data.attributes.weight = Number(data.attributes.weight) || 0;
      data.attributes.endurance = Number(data.attributes.endurance) || 0;
      data.attributes.force = Number(data.attributes.force) || 0;
      data.attributes.agility = Number(data.attributes.agility) || 0;
      data.attributes.intelligence = Number(data.attributes.intelligence) || 0;
      data.attributes.ecoScore = Number(data.attributes.ecoScore) || 0;
    }
  
    // 3. Vérifier et convertir les raceAbilities
    if (!data.raceAbilities || typeof data.raceAbilities !== 'object') {
      data.raceAbilities = {
        raceAbilitiesFirst: { name: '', points: 0 },
        raceAbilitiesSecond: { name: '', points: 0 },
        raceAbilitiesThird: { name: '', points: 0 },
      };
    } else {
      data.raceAbilities.raceAbilitiesFirst = {
        name: String(data.raceAbilities.raceAbilitiesFirst.name || ''),
        points: Number(data.raceAbilities.raceAbilitiesFirst.points) || 0,
      };
      data.raceAbilities.raceAbilitiesSecond = {
        name: String(data.raceAbilities.raceAbilitiesSecond.name || ''),
        points: Number(data.raceAbilities.raceAbilitiesSecond.points) || 0,
      };
      data.raceAbilities.raceAbilitiesThird = {
        name: String(data.raceAbilities.raceAbilitiesThird.name || ''),
        points: Number(data.raceAbilities.raceAbilitiesThird.points) || 0,
      };
    }
  
    // 4. Vérifier et convertir les classActions
    if (!data.classActions || typeof data.classActions !== 'object') {
      data.classActions = {
        classActionsAttack: {
          classActionsAttackFirst: { name: '', points: 0 },
          classActionsAttackSecond: { name: '', points: 0 },
          classActionsAttackThird: { name: '', points: 0 },
        },
        classActionsDefense: {
          classActionsDefenseFirst: { name: '', points: 0 },
          classActionsDefenseSecond: { name: '', points: 0 },
          classActionsDefenseThird: { name: '', points: 0 },
        },
        classActionsHeal: {
          classActionsHealFirst: { name: '', points: 0 },
          classActionsHealSecond: { name: '', points: 0 },
          classActionsHealThird: { name: '', points: 0 },
        },
      };
    } else {
      // Fonction auxiliaire pour convertir les actions
      const convertActions = (actionsCategory) => {
        ['First', 'Second', 'Third'].forEach((suffix) => {
          const key = `${actionsCategory}${suffix}`;
          //console.log(key)
          const actionCategoryKey = `classActions${actionsCategory}`;
          if (data.classActions[actionCategoryKey] && data.classActions[actionCategoryKey][key]) {
            data.classActions[actionCategoryKey][key] = {
              name: String(data.classActions[actionCategoryKey][key].name || ''),
              points: Number(data.classActions[actionCategoryKey][key].points) || 0,
            };
          } else {
            if (!data.classActions[actionCategoryKey]) {
              data.classActions[actionCategoryKey] = {};
            }
            data.classActions[actionCategoryKey][key] = { name: '', points: 0 };
          }
        });
      };
  
      convertActions('Attack');
      convertActions('Defense');
      convertActions('Heal');
    }
  
    // 5. Validation finale (optionnelle)
    // Vous pouvez ajouter des validations supplémentaires ici si nécessaire
  
    return true; // Toujours retourner true après avoir corrigé les données
  };


  export const getEnemyData = async (chapterId) => {
    try {
        const response = await axios.get(`${url_backend}/combat/enemy/${chapterId}`);
        return response.data;
    } catch (error) {
        console.error("Error fetching enemy data:", error);
        return null;
    }
};