import React, { useState } from 'react';

const DiceComponent = ({ onRollComplete }) => {
  const [rolling, setRolling] = useState(false);
  const [result, setResult] = useState(1);
  const [rotation, setRotation] = useState(0);

  const rollDice = () => {
    if (rolling) return; // Éviter les clics multiples pendant le roulement
    setRolling(true);

    let rollTime = 2000; // Durée totale du roulement
    let intervalTime = 100; // Temps entre chaque changement de face

    // Roulement en changeant les faces du dé aléatoirement
    const interval = setInterval(() => {
      const randomResult = Math.ceil(Math.random() * 6);
      setResult(randomResult);
      setRotation(prev => prev + 360); // Ajouter de la rotation à chaque étape
    }, intervalTime);

    // Arrêter le roulement après 2 secondes
    setTimeout(() => {
      clearInterval(interval);
      const finalResult = Math.ceil(Math.random() * 6);
      setResult(finalResult);
      setRolling(false);
      onRollComplete(finalResult);
    }, rollTime);
  };  
  
  return (
    <div className="dice-container" onClick={rollDice}>
      <div
        className={`dice dice-${result}`}
        style={{
          transform: `rotate(${rotation}deg)`,
          transition: rolling ? 'none' : 'transform 1s ease',
        }}
      />
    </div>
  );
};

export default DiceComponent;
