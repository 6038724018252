

export const url_backend = "https://battle-of-angelus.ovh/api";
export const url_base = "https://battle-of-angelus.ovh";
export const gas_mint_createCharacter = 3000000;
export const gasPrice_mint_createCharacter = 50;

export const gas_mint_approuve_market = 3000000;
export const gasPrice_approuve_market = 50;

export const gas_mint_listing_market = 3000000;
export const gasPrice_listing_market = 50;


export const gas_mint_buy_market = 3000000;
export const gasPrice_buy_market = 50;

export const gas_mint_buyAngelus = 3000000;
export const gasPrice_mint_buyAngelus = 50;
export const intervallUpdateCountdownBattle = 1000;
export const timeoutGenerateCharacter = 2000;
export const timeoutItemCharacter = 2000;

export const gas_mint_arme = 3000000;
export const gasPrice_mint_arme = 50;
 
export const gas_mint_armure = 3000000;
export const gasPrice_mint_armure = 50;

export const maxRegenItem = 6;
export const maxRegencharacter = 6;   

export const ecoScoreImpactFactor  = 0.1;

export const gas_token_reward = 3000000;
export const gasPrice_token_reward = 50;

export const gas_stacking = 3000000;
export const gasPrice_stacking = 50;

 