
import { loadContracts, getBalance, getNFTs, getNFTsArmure, getNFTsArme,  connectWallet, getPlayerIdFromBackend } from './blockchainService';
import axios from 'axios';
import { url_backend } from '../config';
import { fetchCurrentChapter } from './storyService'; // Import du service d'histoire
import { getCharactereInfoForBattle} from '../services/outils'

export const loadBlockchainAndBackendData = async (connectedAccount, dispatch) => {
  if (!dispatch) {
    console.error('Dispatch non défini');
    return;
  }
 
  dispatch({ type: 'SET_IS_LOADING', payload: true });
  //console.log("loadBlockchainAndBackendData called");
  //console.trace(); // Affichera la trace d'appel dans la console
  try {
    await loadContracts();
    
    let account = connectedAccount;
    if (!account) {
      account = await connectWallet();
    }
    // Ajout de logs pour vérifier le compte
   // console.log("Account connecté :", account);

    const playerIdBD = await getPlayerIdFromBackend(account);
    dispatch({ type: 'SET_PLAYER_ID', payload: playerIdBD });

    /* Récupération de la balance Angelus Token  */
    const tokenBalance = await getBalance(account);
    // Mettre à jour le state avec les nouvelles données
    dispatch({ type: 'SET_BALANCE', payload: tokenBalance });

     // Log balance
    // console.log("Balance Angelus :", tokenBalance);


    /* Récupération des infos des personnage NFT Angelus   */
    const userNftsInfos = await getNFTs(account);
    //console.log("Infos NFTs récupérées :", userNftsInfos);

    //console.log(userNftsInfos)

 
    const angelusNFTBalance = userNftsInfos.nftBalance;
    let userNfts = userNftsInfos.nfts;
    if (userNfts.length === 0) {
      userNfts = [];
    }

   
    // Mettre à jour le state avec les nouvelles données
    dispatch({ type: 'SET_NFT_BALANCE', payload: angelusNFTBalance });
    dispatch({ type: 'SET_NFTS', payload: userNfts });
   
    /* Récupération des infos des Armures NFT Angelus   */
    const userNFTsArmure = await getNFTsArmure(account);
    //console.log("Infos NFTs Armures :", userNFTsArmure);
    
    //console.log("Infos NFTs Armures :", userNFTsArmure);


    const angelusNFTArmureBalance = userNFTsArmure.nftArmureBalance;
    let userNftsArmures = userNFTsArmure.nftsArmure;
    if (userNftsArmures.length === 0) {
      userNftsArmures = [];
    }

    

    // Mettre à jour le state avec les nouvelles données
    dispatch({ type: 'SET_NFT_ARMURES_BALANCE', payload: angelusNFTArmureBalance });
    dispatch({ type: 'SET_NFTS_ARMURES', payload: userNftsArmures });
    //console.log("state.nftsArmures après dispatch :", userNftsArmures);

    /* Récupération des infos des Armes NFT Angelus   */
    const userNFTsArme = await getNFTsArme(account);
 
    //console.log("Infos NFTs Armes :", userNFTsArme);
 
   const angelusNFTArmeBalance = userNFTsArme.nftArmeBalance;
    let userNftsArmes = userNFTsArme.nftsArme;
    if (!userNftsArmes || userNftsArmes.length === 0) {
      userNftsArmes = [];
    }
    
    // Mettre à jour le state avec les nouvelles données
    dispatch({ type: 'SET_NFT_ARMES_BALANCE', payload: angelusNFTArmeBalance });
    dispatch({ type: 'SET_NFTS_ARMES', payload: userNftsArmes });

    // Récupérer les données du backend
    const response = await axios.post(`${url_backend}/players/infosPlayer`, { wallet_address: account });

    if (response.data) {
      const { isPlayer, isPlayerSelection, isCharacter, playerSelection } = response.data;
      //console.log(response.data)
      dispatch({ type: 'SET_IS_PLAYER', payload: isPlayer });
      dispatch({ type: 'SET_IS_PLAYER_SELECTION', payload: isPlayerSelection });
      dispatch({ type: 'SET_IS_CHARACTER', payload: isCharacter });
      dispatch({ type: 'PLAYER_SELECTION', payload: playerSelection });
      
      // Mettre à jour le backend avec la balance si différente
      if (tokenBalance !== response.data.angelus_balance) {
        await axios.post(`${url_backend}/players/updateBalance`, {
          wallet_address: account,
          angelus_balance: tokenBalance,
        });
      }
 
      if (isPlayerSelection) {
      //  console.log("playerSelection",playerSelection)
        const selectedNft = playerSelection;
        dispatch({ type: 'SET_SELECTED_NFT_FOR_BATTLE', payload: selectedNft });
       
        if (userNfts.length > 0) {
          let selectedNftIndex = -1;

          for (let i = 0; i < userNfts.length; i++) {
            const nft = userNfts[i];
            if (nft.character_id) {
              if (nft.character_id === selectedNft.character_id) {
                selectedNftIndex = i;
                break;
              }
            }
          }
         
          if (selectedNftIndex === -1) {
            console.log("Le NFT sélectionné n'a pas été trouvé dans la liste.");
          } else {
            const newIndex = Math.max(0, selectedNftIndex - (selectedNftIndex % 2));
            dispatch({ type: 'SET_CURRENT_INDEX', payload: newIndex });
          }
        }
      
        await getCharacterInfosForPlay(account, dispatch, playerSelection)
    
      }

      // Charger les données de l'histoire
      const storyData = await fetchCurrentChapter(playerIdBD);
      
      //console.log("storyData" , storyData)
      dispatch({ type: 'SET_CURRENT_CHAPTER', payload: storyData.chapter });
      dispatch({ type: 'UPDATE_ECO_SCORE', payload: storyData.eco_score_change });
      dispatch({ type: 'SET_STORY_PROGRESS', payload: storyData.progress });
      dispatch({ type: 'SET_STORY_DATA', payload: storyData });
    }
    //console.log("test10")
    //console.log(userNfts)
    return userNfts;
  } catch (error) {
    console.error('Erreur lors du chargement des données:', error);
  } finally {
    dispatch({ type: 'SET_IS_LOADING', payload: false });
  }
};


const getCharacterInfosForPlay = async (account, dispatch, playerSelection) => {
  var characterSelectedForBattle = null;
  const character_id = playerSelection.character_id;
  if(!account){
    account = await connectWallet();
  }

  const infoCharacterForBattle = await getCharactereInfoForBattle(character_id, account, dispatch )
  dispatch({ type: 'CHARACTER_FOR_BATTLE_SELECTED', payload: infoCharacterForBattle.character });
  dispatch({ type: 'WEAPON_CHARACTER_FOR_BATTLE_SELECTED', payload: infoCharacterForBattle.weaponCharacter });
  dispatch({ type: 'ARMOR_CHARACTER_FOR_BATTLE_SELECTED', payload: infoCharacterForBattle.armorCharacter });

  characterSelectedForBattle = infoCharacterForBattle.character;
  //console.log(characterSelectedForBattle)
  
  return characterSelectedForBattle
};