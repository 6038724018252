import React, { useEffect, useReducer, useCallback, useState } from 'react';
import { initialState, reducer } from './services/reducer';
import { loadBlockchainAndBackendData } from './services/loadBlockchainAndBackendDataService';
import {
  toggleAccountMenu,
  handleLogout,
  handleMintSuccess,
  nextNfts,
  prevNfts,
  handleNftClick,
  closeDetailModal,
  closeCreateCharacterModal,
  openBuyModal,
  closeBuyModal,
  openMarketplaceModal,
  closeMarketplaceModal,
} from './services/uiHandlers';
import {   connectWallet } from './services/blockchainService';
import { handleConnectWallet, handleAccountChanged, addMetaMaskListeners } from './services/walletHandlers'; // Importation des fonctions
import CreateCharacter from './components/CreateCharacter';
import DetailCharacter from './components/DetailCharacter';
import CentralLoading from './components/CentralLoading';
import { calculateTimeRemainingAndProgress } from './services/outils';
import { intervallUpdateCountdownBattle } from './config';
import BuyAngelus from './components/BuyAngelus';
import Marketplace from './components/Marketplace';
//import DiceComponent from './components/DiceComponent';
import Story from './components/Story';           // Import du composant Story
import Combat from './components/Combat';      
import { startStory } from './services/storyService'; // Import du service startStory

import CharacterDetailsPlay from './components/characterDetailsPlay'; 
import StakingModal from './components/Staking';
 
const App = () => {
  const [state, dispatch] = useReducer(reducer, initialState);
  //const [rollResult, setRollResult] = useState(null);
  const [isStakingModalOpen, setIsStakingModalOpen] = useState(false);

  // Gérer la connexion au wallet
  const connectWalletHandler = useCallback(() => handleConnectWallet(dispatch), [dispatch]);

  // Gérer les changements de compte MetaMask
  const accountChangedHandler = useCallback(
    (accounts) => handleAccountChanged(accounts, dispatch),
    [dispatch]
  );
  
  const openStakingModal = () => {
    setIsStakingModalOpen(true);
  };

  const closeStakingModal = () => {
    setIsStakingModalOpen(false);
  };

  // Initialisation et écoute des événements MetaMask
  useEffect(() => {
    if (window.ethereum) {
      console.log("MetaMask detected, adding listeners");
      addMetaMaskListeners(accountChangedHandler); // Utiliser la fonction externalisée
      connectWalletHandler();
    } else {
      console.error("MetaMask non détecté. Assurez-vous que MetaMask est installé.");
    }
 
    return () => {
      if (window.ethereum) {
        window.ethereum.removeListener('accountsChanged', accountChangedHandler);
        window.ethereum.removeListener('chainChanged', () => window.location.reload());
      }
    };
  }, [connectWalletHandler, accountChangedHandler]);
 
  useEffect(() => {
    const updateTimers = () => {
      const newTimers = {};

      state.nfts.forEach((nft) => {
        const { timeRemaining, progress } = calculateTimeRemainingAndProgress(nft.attributes.next_combat_time);
        newTimers[nft.character_id] = { timeRemaining, progress };
      });

      //console.log(newTimers)
      dispatch({ type: 'SET_NFT_TIMERS', payload: newTimers });
    };

    const interval = setInterval(updateTimers, intervallUpdateCountdownBattle);
    return () => clearInterval(interval);
  }, [state.nfts, dispatch]);


  // Fonction pour démarrer l'histoire
  const startStoryHandler = async () => {
    if (!state.playerId) {
      alert('Player ID manquant. Veuillez vous connecter.');
      return;
    }

    try {
      dispatch({ type: 'SET_IS_LOADING', payload: true });
      const storyResponse = await startStory(state.playerId);
      
      // Supposons que l'API renvoie le premier chapitre et les informations d'éco-score
      dispatch({ type: 'SET_CURRENT_CHAPTER', payload: storyResponse.chapter });
      dispatch({ type: 'UPDATE_ECO_SCORE', payload: storyResponse.eco_score_change });
      dispatch({ type: 'SET_STORY_PROGRESS', payload: storyResponse.progress });

      alert('Histoire démarrée avec succès !');
      
      const  connectedAccount = await connectWallet();
        
     
      await loadBlockchainAndBackendData(connectedAccount, dispatch);
    } catch (error) {
      console.error('Erreur lors du démarrage de l\'histoire:', error);
      alert('Erreur lors du démarrage de l\'histoire.');
    } finally {
      dispatch({ type: 'SET_IS_LOADING', payload: false });
    }
  };

 
  //console.log("state",state)

  return (
    <div className="App" style={{ backgroundImage: `url("https://battle-of-angelus.ovh/images/wallpaper.png")` }}>
      <CentralLoading isLoading={state.isLoading} />

      <header className="App-header">
        <div className="text-background">
          <h1>The Battle of Angelus</h1>
          <nav className="navbar">
            <div className="navbar-content">
              <div className="token-info">
                <span>
                  <img
                    src="https://battle-of-angelus.ovh/images/angIco.png"
                    alt="Angelus"
                    className="crypto-icon"
                    onMouseEnter={() => dispatch({ type: 'SET_CRYPTO_TOOLTIP_VISIBLE', payload: true })}
                    onMouseLeave={() => dispatch({ type: 'SET_CRYPTO_TOOLTIP_VISIBLE', payload: false })}
                  />
                  {state.cryptoTooltipVisible && <span className="tooltip">Angelus</span>}
                  {state.balance}
                </span>
                <button className="buy-button" onClick={() => openBuyModal(dispatch)}>+</button>
              </div>
              <div className="nft-info">
                <span>
                  <img src="https://battle-of-angelus.ovh/images/persoIco.png" alt="Personnages" className="person-icon" />
                  {state.nftBalance.toString()}
                </span>
              </div>
              {/* Ajouter le bouton "Marché" ici */}
              <div className="marketplace-button">
                <button onClick={() => openMarketplaceModal(dispatch)}>Marché</button>
                <button onClick={openStakingModal}>Staking</button>
              </div>
              
              <div className="account-info">
                {state.account ? (
                  <button onClick={() => toggleAccountMenu(dispatch)}>Mon Compte</button>
                ) : (
                  <button onClick={connectWalletHandler}>Se Connecter</button>
                )}
              </div>
            </div>
          </nav>
          {state.isAccountMenuOpen && (
            <div className="account-popup">
              <p>Compte : {state.account}</p>
              <button onClick={() => handleLogout(dispatch)}>Déconnexion</button>
            </div>
          )}

        <div className="arena-section">
          {state.nftBalance < 1 && (
            <h2>Veuillez générer un personnage, une armure et une arme pour commencer à jouer</h2>
          )}



          {state.nftBalance > 0 && !state.playerSelection && (
            <h2>Veuillez sélectionner un personnage pour jouer</h2>
          )}

          {state.isCharacter && state.selectedNftForBattle && (
            <div className="content_arene_combat">
              <div className='arena-gauche'>
             
              {state.playerSelection &&  ( 
              
                <CharacterDetailsPlay 
                playerId={state.playerId} 
                dispatch={dispatch} 
                playerSelection={state.playerSelection} 
                infosCharacterForPlay={state.characterSelectedForBattle}
                weaponCharacterSelectedForBattle={state.weaponCharacterSelectedForBattle}
                armorCharacterSelectedForBattle={state.armorCharacterSelectedForBattle}
                account={state.account}
                />
              )}


              </div>
              <div className='arena-centre'>
              
              


              {/* Intégration des composants Story et Combat dans 'arena-combat' */}
      <div className='arena-combat'>
        {state.currentCombat && state.combatState === 'ongoing' ? (
          <Combat 
            playerId={state.playerId} 
            dispatch={dispatch} 
            currentCombat={state.currentCombat} 
            combatState={state.combatState} 
            combatLog={state.combatLog} 
            playerSelection={state.playerSelection} 
            account={state.account}
            infosCharacterForPlay={state.characterSelectedForBattle}
            weaponCharacterSelectedForBattle={state.weaponCharacterSelectedForBattle}
            armorCharacterSelectedForBattle={state.armorCharacterSelectedForBattle}
          />
        ) : state.currentChapter ? (
          <Story 
            playerId={state.playerId} 
            dispatch={dispatch} 
            currentChapter={state.currentChapter} 
            ecoScore={state.characterSelectedForBattle.attributes.ecoScore} 
            storyData={state.storyData}
            playerSelection={state.playerSelection} 
            account={state.account}
            state={state}
            infosCharacterForPlay={state.characterSelectedForBattle}
            weaponCharacterSelectedForBattle={state.weaponCharacterSelectedForBattle}
            armorCharacterSelectedForBattle={state.armorCharacterSelectedForBattle}

          />
        ) : (
          <div className="start-story-container">
            <p>Veuillez démarrer une histoire pour commencer.</p>
            <button className="start-story-button" onClick={startStoryHandler}>Démarrer l'Histoire</button>
          </div>
        )}
      </div>


 {/*
                <div className="dice-section">

                  <h3>Jetez le dé !</h3>
                  <DiceComponent onRollComplete={(result) => setRollResult(result)} />
                  {rollResult && <p>Résultat du dés : {rollResult}</p>}
                </div>
*/}


              </div>
              <div className='arena-droite'>
  {/* Si un combat est en cours, afficher les informations de l'ennemi ici */}
  {state.currentCombat && state.currentCombat.enemy && (
    <div className="enemy-details">
      <h2>{state.currentCombat.enemy.name}</h2>
      {state.currentCombat.enemy.image_url && <img src={`${state.currentCombat.enemy.image_url}`} alt="state.currentCombat.enemy.name" className="enemy-image" />}
      <p className='p_nopadding'>{state.currentCombat.enemy.description}</p>
      <p className='p_nopadding_bottom'>Force: {state.currentCombat.enemy.strength}</p>
      <p className='p_nopadding'>Agilité: {state.currentCombat.enemy.agility}</p>
      <p className='p_nopadding'>Intelligence: {state.currentCombat.enemy.intelligence}</p>
      <p className='p_nopadding'>Endurance: {state.currentCombat.enemy.endurance}</p>
      <p className='p_nopadding_bottom'>Vie: {state.currentCombat.enemy.health}</p>
      <p className='p_nopadding'>Défense: {state.currentCombat.enemy.defense}</p>
      <p className='p_nopadding_bottom' >Attaque 1: {state.currentCombat.enemy.attack_action_1}</p>
      <p className='p_nopadding'>Attaque 2: {state.currentCombat.enemy.attack_action_2}</p>
      <p className='p_nopadding_bottom'>Défense 1: {state.currentCombat.enemy.defense_action_1}</p>
      <p className='p_nopadding'>Défense 2: {state.currentCombat.enemy.defense_action_2}</p>
      <p className='p_nopadding_bottom'>Soin 1: {state.currentCombat.enemy.heal_action_1}</p>
      <p className='p_nopadding'>Soin 2: {state.currentCombat.enemy.heal_action_2}</p>
    </div>
  )}
</div>
            </div>
          )}
        </div>



          <div className="nft-display">
            {state.nftBalance > 0 ? (
              <div className="nft-container">
                {state.nfts.slice(state.currentIndex, state.currentIndex + 2).map((nft, index) => {
                  const timerData = state.nftTimers[nft.character_id] || { timeRemaining: '...', progress: 0 };
                  const { timeRemaining, progress } = timerData;

                  return (
                    <div
                      key={index}
                      className={`nft-card ${state.selectedNftForBattle?.character_id === nft.character_id ? 'selected-nft' : ''}`}
                      onClick={() => handleNftClick(dispatch, nft)}
                    >
                      <h4>{nft.name} {nft.gender}</h4>
                      <p>{nft.race} {nft.class}</p>
                      <img src={nft.imageFront} alt={nft.name} style={{ width: '100px' }} />

                      {/* Barre de progression */}
                      <div className="combat-timer">
                        <h4>
                          {timeRemaining === 'Prêt pour le combat'
                            ? 'Prêt pour le combat'
                            : `Prochain combat dans : ${timeRemaining}`}
                        </h4>
                        <div className="progress-bar-container">
                          <div className="progress-bar" style={{ width: `${progress}%` }}></div>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
            ) : (
              <p>Vous n'avez pas de personnage. Veuillez en créer un !</p>
            )}
          </div>
 
          {state.nftBalance > 2 && (
            <div className="nft-navigation">
              <button onClick={() => prevNfts(state, dispatch)} disabled={state.currentIndex === 0}>&lt; Précédent</button>
              <button onClick={() => nextNfts(state, dispatch)} disabled={state.currentIndex + 2 >= state.nftBalance}>Suivant &gt;</button>
            </div>
          )}

          <CreateCharacter
            account={state.account}
            onCharacterCreated={(character) => dispatch({ type: 'SET_CHARACTER', payload: character })}
            generateCount={state.generateCount}
            setGenerateCount={(count) => dispatch({ type: 'SET_GENERATE_COUNT', payload: count })}
            characterMinted={state.characterMinted}
            character={state.character}
            setCharacterMinted={(minted) => dispatch({ type: 'SET_CHARACTER_MINTED', payload: minted })}
            onMintSuccess={() => handleMintSuccess(dispatch, connectWalletHandler)}
            dispatch={dispatch}
            playerId={state.playerId}
            closeModal={() => closeCreateCharacterModal(dispatch)}
          />

          
        </div>
      </header>

      {state.showModalDetail && state.selectedNft && (
        <DetailCharacter
          nft={state.selectedNft}
          onClose={() => closeDetailModal(dispatch, state.account, loadBlockchainAndBackendData)}
          playerId={state.playerId}
          dispatch={dispatch}
          state={state}
          connectedAccount={state.account}
          loadBlockchainAndBackendData={loadBlockchainAndBackendData}
        />
      )}

      {state.showModalBuyAngelus && <BuyAngelus dispatch={dispatch} state={state} onClose={() => closeBuyModal(dispatch)} />}

      {state.showMarketplaceModal && (
        <div className="modal-market">
          <div className="modal-market-content">
            <span className="close-market" onClick={() => closeMarketplaceModal(dispatch)}>&times;</span>
            <Marketplace connectedAccount={state.account} />
          </div>
        </div>
      )}

{isStakingModalOpen && (
        <StakingModal onClose={closeStakingModal} playerId={state.playerId} />
      )}
    </div>
  );
};

export default App;
