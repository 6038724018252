// Initial state for useReducer
export const initialState = {
    account: '',
    balance: '0',
    nftBalance: '0',
    nftArmesBalance: '0',
    nftArmuresBalance: '0',
    generateCount: 5,
    generateCountItem: 5,
    characterMinted: false,
    character: null,
    nfts: [],
    nftsArmures: [],
    nftsArmes: [],
    showModal: false,
    showModalDetail: false,
    showModalBuyAngelus: false,
    isLoading: false,
    currentIndex: 0,
    selectedNft: null,
    selectedNftArmors: null,
    selectedNftWeapons: null,
    selectedNftForBattle: null,
    isAccountMenuOpen: false,
    cryptoTooltipVisible: false,
    playerId: null,
    isPlayer: false,
    isPlayerSelection: false,
    isCharacter: false,
    nftTimers: {},
    amountAngelus: '', // Le montant d'Angelus à acheter
    priceInMatic: 0,   // Le prix en MATIC calculé
    errorMessage: '',  // Message d'erreur en cas de problème
    isGeneratingItem: false,
    showMarketplaceModal: false,
    storyData: null, 
    currentChapter: null, // Informations sur le chapitre actuel
    ecoScore: 0,
    progress: null, // Score écologique
    storyProgress: 0, // Progression dans l'histoire
    currentCombat: null, // Informations sur le combat en cours
    combat: null,
    combatState: 'idle', // 'idle', 'ongoing', 'victory', 'defeat'
    combatLog: [], // Historique des actions du combat
    playerSelection: null,
    characterSelectedForBattle: null,
    weaponCharacterSelectedForBattle: null,
    armorCharacterSelectedForBattle: null
};
 
// Reducer function to manage the state changes
export const reducer = (state, action) => {
    switch (action.type) {
        case 'SET_ACCOUNT':
            if (typeof action.payload === 'string') {
                return { ...state, account: action.payload };
            }
            console.error('SET_ACCOUNT: payload invalide');
            return state;

        case 'SET_BALANCE':
            if (!isNaN(action.payload)) {
                return { ...state, balance: action.payload };
            }
            console.error('SET_BALANCE: payload invalide');
            return state;

        case 'SET_NFT_BALANCE':
            if (!isNaN(action.payload)) {
                return { ...state, nftBalance: action.payload };
            }
            console.error('SET_NFT_BALANCE: payload invalide');
            return state;

        case 'SET_NFT_ARMES_BALANCE':
            if (!isNaN(action.payload)) {
                return { ...state, nftArmesBalance: action.payload };
            }
            console.error('SET_NFT_ARMES_BALANCE: payload invalide');
            return state;

        case 'SET_NFT_ARMURES_BALANCE':
            if (!isNaN(action.payload)) {
                return { ...state, nftArmuresBalance: action.payload };
            }
            console.error('SET_NFT_ARMURES_BALANCE: payload invalide');
            return state;

        case 'SET_GENERATE_COUNT':
            if (!isNaN(action.payload)) {
                return { ...state, generateCount: action.payload };
            }
            console.error('SET_GENERATE_COUNT: payload invalide');
            return state;

        case 'SET_GENERATE_COUNT_ITEM':
            if (!isNaN(action.payload)) {
                return { ...state, generateCountItem: action.payload };
            }
            console.error('SET_GENERATE_COUNT_ITEM: payload invalide');
            return state;

        case 'SET_CHARACTER_MINTED':
            if (typeof action.payload === 'boolean') {
                return { ...state, characterMinted: action.payload };
            }
            console.error('SET_CHARACTER_MINTED: payload invalide');
            return state;

        case 'SET_CHARACTER':
            if (action.payload) {
                return { ...state, character: action.payload };
            }
            console.error('SET_CHARACTER: payload invalide');
            return state;

        case 'SET_NFTS':
            if (Array.isArray(action.payload)) {
                return { ...state, nfts: action.payload };
            }
            console.error('SET_NFTS: payload invalide');
            return state;

        case 'SET_NFTS_ARMURES':
            if (Array.isArray(action.payload)) {
                return { ...state, nftsArmures: action.payload };
            }
            console.error('SET_NFTS_ARMURES: payload invalide');
            return state;

        case 'SET_NFTS_ARMES':
            if (Array.isArray(action.payload)) {
                return { ...state, nftsArmes: action.payload };
            }
            console.error('SET_NFTS_ARMES: payload invalide');
            return state;

        case 'SET_SHOW_MODAL':
            if (typeof action.payload === 'boolean') {
                return { ...state, showModal: action.payload };
            }
            console.error('SET_SHOW_MODAL: payload invalide');
            return state;

        case 'SET_SHOW_MODAL_DETAIL':
            if (typeof action.payload === 'boolean') {
                return { ...state, showModalDetail: action.payload };
            }
            console.error('SET_SHOW_MODAL_DETAIL: payload invalide');
            return state;

        case 'SET_SHOW_MODAL_BUY_ANGELUS':
            if (typeof action.payload === 'boolean') {
                return { ...state, showModalBuyAngelus: action.payload };
            }
            console.error('SET_SHOW_MODAL_BUY_ANGELUS: payload invalide');
            return state;

        case 'SET_IS_LOADING':
            if (typeof action.payload === 'boolean') {
                return { ...state, isLoading: action.payload };
            }
            console.error('SET_IS_LOADING: payload invalide');
            return state;

        case 'SET_CURRENT_INDEX':
            if (!isNaN(action.payload)) {
                return { ...state, currentIndex: action.payload };
            }
            console.error('SET_CURRENT_INDEX: payload invalide');
            return state;

        case 'SET_SELECTED_NFT':
            if (action.payload) {
                return { ...state, selectedNft: action.payload };
            }
            console.error('SET_SELECTED_NFT: payload invalide');
            return state;

        case 'SET_SELECTED_NFT_ARMORS':
            if (action.payload) {
                return { ...state, selectedNftArmors: action.payload };
            }
            console.error('SET_SELECTED_NFT_ARMORS: payload invalide');
            return state;

        case 'SET_SELECTED_NFT_WEAPONS':
            if (action.payload) {
                return { ...state, selectedNftWeapons: action.payload };
            }
            console.error('SET_SELECTED_NFT_WEAPONS: payload invalide');
            return state;

        case 'SET_SELECTED_NFT_FOR_BATTLE':
            if (action.payload) {
                return { ...state, selectedNftForBattle: action.payload };
            }
            console.error('SET_SELECTED_NFT_FOR_BATTLE: payload invalide');
            return state;

        case 'TOGGLE_ACCOUNT_MENU':
            return { ...state, isAccountMenuOpen: !state.isAccountMenuOpen };

        case 'SET_CRYPTO_TOOLTIP_VISIBLE':
            if (typeof action.payload === 'boolean') {
                return { ...state, cryptoTooltipVisible: action.payload };
            }
            console.error('SET_CRYPTO_TOOLTIP_VISIBLE: payload invalide');
            return state;

        case 'SET_PLAYER_ID':
            if (action.payload) {
                return { ...state, playerId: action.payload };
            }
            console.error('SET_PLAYER_ID: payload invalide');
            return state;

        case 'SET_IS_PLAYER':
            if (typeof action.payload === 'boolean') {
                return { ...state, isPlayer: action.payload };
            }
            console.error('SET_IS_PLAYER: payload invalide');
            return state;

        case 'SET_IS_PLAYER_SELECTION':
            if (typeof action.payload === 'boolean') {
                return { ...state, isPlayerSelection: action.payload };
            }
            console.error('SET_IS_PLAYER_SELECTION: payload invalide');
            return state;

        case 'SET_IS_CHARACTER':
            if (typeof action.payload === 'boolean') {
                return { ...state, isCharacter: action.payload };
            }
            console.error('SET_IS_CHARACTER: payload invalide');
            return state;

        case 'SET_NFT_TIMERS':
            if (action.payload) {
                return {
                    ...state,
                    nftTimers: { ...state.nftTimers, ...action.payload },
                };
            }
            console.error('SET_NFT_TIMERS: payload invalide');
            return state;

        case 'SET_AMOUNT_ANGELUS':
            if (!isNaN(action.payload)) {
                return { ...state, amountAngelus: action.payload };
            }
            console.error('SET_AMOUNT_ANGELUS: payload invalide');
            return state;

        case 'SET_PRICE_IN_MATIC':
            if (!isNaN(action.payload)) {
                return { ...state, priceInMatic: action.payload };
            }
            console.error('SET_PRICE_IN_MATIC: payload invalide');
            return state;

        case 'SET_ERROR_MESSAGE':
            if (typeof action.payload === 'string') {
                return { ...state, errorMessage: action.payload };
            }
            console.error('SET_ERROR_MESSAGE: payload invalide');
            return state;

        case 'SET_IS_GENERATING_ITEM':
            if (typeof action.payload === 'boolean') {
                return { ...state, isGeneratingItem: action.payload };
            }
            console.error('SET_IS_GENERATING_ITEM: payload invalide');
            return state;

        case 'OPEN_MARKETPLACE_MODAL':
            return { ...state, showMarketplaceModal: true };

        case 'CLOSE_MARKETPLACE_MODAL':
            return { ...state, showMarketplaceModal: false };

        // Actions pour l'histoire
        case 'SET_STORY_DATA':
            if (action.payload) {
                return { ...state, storyData: action.payload };
            }
            console.error('SET_STORY_DATA: payload invalide');
            return state;

        case 'SET_CURRENT_CHAPTER':
            if (action.payload) {
                return { ...state, currentChapter: action.payload };
            }
            console.error('SET_CURRENT_CHAPTER: payload invalide');
            return state;

        case 'SET_ECO_SCORE':
            if (!isNaN(action.payload)) {
                return { ...state, ecoScore: action.payload };
            }
            console.error('SET_ECO_SCORE: payload invalide');
            return state;

        case 'UPDATE_ECO_SCORE':
            if (!isNaN(action.payload)) {
                return { ...state, ecoScore: state.ecoScore + action.payload };
            }
            console.error('UPDATE_ECO_SCORE: payload invalide');
            return state;

        case 'SET_STORY_PROGRESS':
            if (!isNaN(action.payload)) {
                return { ...state, storyProgress: action.payload };
            }
            console.error('SET_STORY_PROGRESS: payload invalide');
            return state;

        // Actions pour les combats
        case 'SET_CURRENT_COMBAT':
            if (action.payload) {
                return { ...state, currentCombat: action.payload };
            }
            console.error('SET_CURRENT_COMBAT: payload invalide');
            return state;

        case 'UPDATE_COMBAT_STATE':
            if (typeof action.payload === 'string') {
                return { ...state, combatState: action.payload };
            }
            console.error('UPDATE_COMBAT_STATE: payload invalide');
            return state;

        case 'ADD_TO_COMBAT_LOG':
            if (typeof action.payload === 'string') {
                return { ...state, combatLog: [...state.combatLog, action.payload] };
            }
            console.error('ADD_TO_COMBAT_LOG: payload invalide');
            return state;

        case 'RESET_COMBAT':
            return { ...state, currentCombat: null, combatState: 'idle', combatLog: [] };

        case 'PLAYER_SELECTION':
            if (action.payload) {
                return { ...state, playerSelection: action.payload };
            }
            console.error('PLAYER_SELECTION: payload invalide');
            return state;

        case 'CHARACTER_FOR_BATTLE_SELECTED':
            if (action.payload) {
                return { ...state, characterSelectedForBattle: action.payload };
            }
            console.error('CHARACTER_FOR_BATTLE_SELECTED: payload invalide');
            return state;

        case 'WEAPON_CHARACTER_FOR_BATTLE_SELECTED':
            if (action.payload) {
                return { ...state, weaponCharacterSelectedForBattle: action.payload };
            }
            console.error('WEAPON_CHARACTER_FOR_BATTLE_SELECTED: payload invalide');
            return state;

        case 'ARMOR_CHARACTER_FOR_BATTLE_SELECTED':
            if (action.payload) {
                return { ...state, armorCharacterSelectedForBattle: action.payload };
            }
            console.error('ARMOR_CHARACTER_FOR_BATTLE_SELECTED: payload invalide');
            return state;

        default:
            return state;
    }
};
